import React, { memo } from "react";
import "../style/components-style/ProcessEnvFactorButton.css";
import config from "../config";
import { translations } from "../app_components/Translation";

function DetailsTableButton({ selectedScope, selectedDetails = [], tableGridApi, refreshData, selectedLanguage }) {

    const selectedText = translations[selectedLanguage].processEnvFactorButton;

    const createNewDetailsData = () => {
        return {
            id: undefined,
            calculation_method: '',
            type: '',
            category: '',
            description: '',
            quantity: 0,
            unit: '',
            factor: 1,
            totalco2eq: 0.0,
            emission_id: selectedScope.id,
        }
    };

    const addRow = () => {
        if (!tableGridApi) return;
        tableGridApi.applyTransaction({ add: [createNewDetailsData()] });
    };

    const deleteSelectedRow = () => {
        if (!tableGridApi || selectedDetails.length === 0) return;

        const selectedIds = selectedDetails.map(row => row.id);
        const tableName = 'emission_details';

        fetch(`${config.apiUrl}/delete_row`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ table_name: tableName, selected_ids: selectedIds })
        })
            .then(response => response.json())
            .then(data => {
                console.log('Deleted:', data);
                // Remove the deleted rows from the table
                tableGridApi.applyTransaction({ remove: selectedDetails });
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    const saveTable = () => {
        const requestData = [];
        tableGridApi.forEachNode(node => requestData.push(node.data));
        console.log('requestData:', requestData);

        const endpoint = 'save_emission_details';
        fetch(`${config.apiUrl}/${endpoint}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
        })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                refreshData(); // Refresh the data after saving
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    return (
        <div className="button-container">
            <div className="button-container-left">
                <button onClick={addRow} disabled={(!selectedScope)}>
                    {selectedText.addRow}
                </button>
                <button onClick={deleteSelectedRow} disabled={(selectedDetails.length === 0)}>
                    {selectedText.deleteSelectedRow}
                </button>
            </div>
            <div className="button-container-right">
                <button onClick={saveTable}>{selectedText.saveTable} </button>
            </div>
        </div>
    )
}

export default memo(DetailsTableButton);