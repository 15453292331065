import React, { useState, useEffect } from 'react';
import '../style/components-style/LifeCycleAssessment.css';
import { Icon } from '@iconify/react';
import config from '../config';
import { translations } from '../app_components/Translation';
import { useAuth } from '../authentication/AuthProvider';

function LifeCycleAssessment({ selectedRow, selectedLanguage, setLcaData }) {
    const [isOpen, setIsOpen] = useState(true);
    const [processData, setProcessData] = useState([]);
    const [envFactors, setEnvFactors] = useState([]);
    const [gwpInfo, setGwpInfo] = useState([]);
    const [lcaInfo, setLcaInfo] = useState([]);
    const [editableLcaInfo, setEditableLcaInfo] = useState({});
    const selectedText = translations[selectedLanguage]?.lifeCycleAssessment || {};
    const { user } = useAuth();
    const userId = user ? user.username : null;
    const [isDefaultUsed, setIsDefaultUsed] = useState(false);


    // Static references from the original code
    const staticReferences = [
        'ISO14025: 2006-Environmental labels and declarations -Type III environmental declarations -Principles and procedures',
        'ISO14040: 2006-Environmental management- Life cycle assessment -Principles and framework',
        'ISO14044: 2006/Amd 2: 2020 -Environmental management -Life cycle assessment -Requirements and guidelines',
        'ISO21930: 2017 Sustainability in buildings and civil engineering works -Core rules for environmental product declarations of construction products and services',
        'Part A: Life Cycle Assessment Calculation Rules and Report Requirements UL Environment (December 2018, version 3.2)',
        'Part B: Concrete Masonry and Segmental Concrete Paving Product EPD Requirements ULEnvironment (November 2020, v1.0)',
    ];

    const toggleContainer = () => {
        setIsOpen(!isOpen);
    };

    const handleChange = (field, value) => {
        setEditableLcaInfo(prev => ({
            ...prev,
            [field]: value
        }));
    };

    const generalInfo = (label, value, field) => (
        <div className="info-row">
            <div className="label">{label}</div>
            <textarea
                className="general-info-textarea"
                value={editableLcaInfo[field] || value}
                onChange={(e) => handleChange(field, e.target.value)}
            />
        </div>
    );    

    const textArea = (content, field, isReadOnly = false) => (
        <div>
            <textarea
                className={isReadOnly ? 'readonly-textarea' : 'editable-textarea'}
                value={editableLcaInfo[field] || content}
                onChange={(e) => !isReadOnly && handleChange(field, e.target.value)}
                readOnly={isReadOnly}
            />
        </div>
    );

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${config.apiUrl}/fetch_data`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        table_name: 'processes',
                        condition: `product_id = '${selectedRow.id}' OR design_id = '${selectedRow.id}'`
                    })
                });
                const processes = await response.json();
    
                setProcessData(processes);
    
                const envFactorsPromises = processes.map(process =>
                    fetch(`${config.apiUrl}/api/get-env-factor-by-process-id?process_id=${process.id}`)
                        .then(res => res.json())
                );
    
                const envFactorsResults = await Promise.all(envFactorsPromises);
                const envFactorsData = envFactorsResults.flat();
    
                setEnvFactors(envFactorsData);
    
                const gwpResponse = await fetch(`${config.apiUrl}/fetch_data`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        table_name: 'GWP_info',
                    })
                });
                const gwpInfoData = await gwpResponse.json();
    
                setGwpInfo(gwpInfoData);
    
                // Initialize editableLcaInfo with your provided values
                setEditableLcaInfo({
                    referencePCR: 'Part B: Concrete Masonry and Segmental Concrete Paving, Product EPD Requirements, November 11 2020. V1.0. This Environmental analysis was conducted in accordance with ISO14044 and the reference PCR by: Vrtta Green Solutions Inc. Environmental Consultants',
                    declaredUnit: '',
                    scope: 'Cradle to Gate',
                    lcaSoftware: 'Vrtta Dashboard',
                    lciDatabase: 'Ecoinvent, USLCI, US-EI',
                    lciaMethodology: 'TRACI 2.1 v1.04. This declaration was independently verified in accordance with ISO14025:2006. The UL Environment "Part A: Calculation Rules for the Life Cycle Assessment and Requirements on the Project Report" v3.2 (December 2018), in conformance with ISO 21930:2017, serves as the core PCR, with additional considerations from the USGBC/UL environment Part A Enhancement (2017).',
                    cutOff: 'Items excluded from system boundary include: production, manufacture, and construction of manufacturing capital goods and infrastructure; production and manufacture of production equipment, delivery vehicles, and laboratory equipment; personnel-related activities (travel, furniture, and office supplies); and energy and water use related to company management and sales activities that may be located either within the factory site or at another location. No known flows were deliberately excluded from this EPD.',
                    allocationProcedure: 'Allocation follows the requirements and guidance Part A: Life Cycle Assessment Calculation Rules and Report Requirements, section 3.3. The product category rules for this EPD recognize fly ash, silica fume and slag as waste products recovered materials and thus the environmental impacts allocated to these materials are limited to the treatment and transportation required to use as a concrete material input.',
                    primarySources: [
                        'Aggregate (natural): US-EI(2020):“Gravel, round, at mine/US”, 2001',
                        'Aggregate (recycled concrete): custom process, based on US-EI (2016) “Gravel, crushed, at mine/US”, 2001',
                        'Cleaning Chemicals: Ecoinvent3.4:50%Citric acid and 50% Phosphoric acid, industrial grade, without water, in 70% solution state, market for/GLO, 2017',
                        'Diesel: USLCI (2015): “Diesel, combusted in industrial equipment/NREL/US”, 2007',
                        'Electricity (WECC): Ecoinvent 3.4: “Electricity, medium voltage, market for, cut-off”, 2015',
                        'Natural gas: USLCI(2015): “Natural gas, combusted in industrial boiler/NREL/US”, 2007',
                        'Non-Hazardous Solid Waste: US-EI (2016): Disposal, municipal solid waste, 2008',
                        'Oils, Lubricants and Greases: Ecoinvent 3.5: Lubricating oil, GLO, market for, cut-off, 2011',
                        'Portland Cement: Supplier specific primary data, 2021',
                        'Propane:  USLCI(2015): “Liquefied petroleum gas, at refinery/NREL/US, 2007',
                        'Truck transport:  USLCI(2015): "Transport, combination truck, short-haul, diesel powered/tkm/RNA", 2010'
                    ].join('\n'),
                    interpretation: 'The material extraction (A1) product stage dominates most of the potential environmental impacts with cement contributing the greatest percentage of impacts in this stage. This EPD was calculated using manufacturer specific cement data that represents 100% of the total cement used in this mix. Life cycle impact assessment (LCIA) results are relative expressions and do not predict impacts on category endpoints, the exceeding of thresholds, safety margins or risks (ISO 14044, ISO 14040). EPDs are comparable only if they comply with ISO 21930 (2017), use the same, sub-category PCR where applicable, include all relevant information modules and are based on equivalent scenarios with respect to the context of construction works.',
                });
    
                setLcaData({
                    generalInformation: {
                        ReferencePCR: 'Part B: Concrete Masonry and Segmental Concrete Paving, Product EPD Requirements, November 11 2020. V1.0. This Environmental analysis was conducted in accordance with ISO14044 and the reference PCR by: Vrtta Green Solutions Inc. Environmental Consultants',
                        DeclaredUnit: '',
                        Scope: 'Cradle to Gate',
                        LCA_Software: 'Vrtta Dashboard',
                        LCI_Database: 'Ecoinvent, USLCI, US-EI',
                        LCIA_Methodology: 'TRACI 2.1 v1.04. This declaration was independently verified in accordance with ISO14025:2006. The UL Environment "Part A: Calculation Rules for the Life Cycle Assessment and Requirements on the Project Report" v3.2 (December 2018), in conformance with ISO 21930:2017, serves as the core PCR, with additional considerations from the USGBC/UL environment Part A Enhancement (2017).',
                    },
                    cutOff: 'Items excluded from system boundary include: production, manufacture, and construction of manufacturing capital goods and infrastructure; production and manufacture of production equipment, delivery vehicles, and laboratory equipment; personnel-related activities (travel, furniture, and office supplies); and energy and water use related to company management and sales activities that may be located either within the factory site or at another location. No known flows were deliberately excluded from this EPD.',
                    allocationProcedure: 'Allocation follows the requirements and guidance Part A: Life Cycle Assessment Calculation Rules and Report Requirements, section 3.3. The product category rules for this EPD recognize fly ash, silica fume and slag as waste products recovered materials and thus the environmental impacts allocated to these materials are limited to the treatment and transportation required to use as a concrete material input.',
                    primarySources: [
                        'Aggregate (natural): US-EI(2020):“Gravel, round, at mine/US”, 2001',
                        'Aggregate (recycled concrete): custom process, based on US-EI (2016) “Gravel, crushed, at mine/US”, 2001',
                        'Cleaning Chemicals: Ecoinvent3.4:50%Citric acid and 50% Phosphoric acid, industrial grade, without water, in 70% solution state, market for/GLO, 2017',
                        'Diesel: USLCI (2015): “Diesel, combusted in industrial equipment/NREL/US”, 2007',
                        'Electricity (WECC): Ecoinvent 3.4: “Electricity, medium voltage, market for, cut-off”, 2015',
                        'Natural gas: USLCI(2015): “Natural gas, combusted in industrial boiler/NREL/US”, 2007',
                        'Non-Hazardous Solid Waste: US-EI (2016): Disposal, municipal solid waste, 2008',
                        'Oils, Lubricants and Greases: Ecoinvent 3.5: Lubricating oil, GLO, market for, cut-off, 2011',
                        'Portland Cement: Supplier specific primary data, 2021',
                        'Propane:  USLCI(2015): “Liquefied petroleum gas, at refinery/NREL/US, 2007',
                        'Truck transport:  USLCI(2015): "Transport, combination truck, short-haul, diesel powered/tkm/RNA", 2010'
                    ].join('\n'),
                    interpretation: 'The material extraction (A1) product stage dominates most of the potential environmental impacts with cement contributing the greatest percentage of impacts in this stage. This EPD was calculated using manufacturer specific cement data that represents 100% of the total cement used in this mix. Life cycle impact assessment (LCIA) results are relative expressions and do not predict impacts on category endpoints, the exceeding of thresholds, safety margins or risks (ISO 14044, ISO 14040). EPDs are comparable only if they comply with ISO 21930 (2017), use the same, sub-category PCR where applicable, include all relevant information modules and are based on equivalent scenarios with respect to the context of construction works.',
                    formattedReferences: staticReferences.join('\n'),
                });
    
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
    
        fetchData();
    }, [selectedRow]);


    const fetchLcaInfo = async (condition) => {
        try {
            const response = await fetch(`${config.apiUrl}/fetch_data`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    table_name: 'LCA_info',
                    condition
                })
            });
            const lcaInfoData = await response.json();
            return lcaInfoData;
        } catch (error) {
            console.error('Error fetching LCA info:', error);
            return null;
        }
    };

    const loadLcaInfo = async () => {
        if (userId) {
            let lcaInfoData = await fetchLcaInfo(`user_id = '${userId}'`);

            // If response is empty, redo fetch with user_id = 'default'
            if (lcaInfoData && lcaInfoData.length === 0) {
                console.log("No data for user, fetching default LCA info.");
                lcaInfoData = await fetchLcaInfo(`user_id = 'default'`);
                setIsDefaultUsed(true);
            }

            if (lcaInfoData && lcaInfoData.length > 0) {
                const data = lcaInfoData[0];
                setEditableLcaInfo({
                    referencePCR: data.reference_pcr || 'No data',
                    declaredUnit: data.declared_unit || 'No data',
                    scope: data.scope || 'No data',
                    lcaSoftware: data.lca_software || 'No data',
                    lciDatabase: data.lci_database || 'No data',
                    lciaMethodology: data.lcia_methodology || 'No data',
                    cutOff: data.cutoff || 'No data',
                    allocationProcedure: data.allocation_procedure || 'No data',
                    primarySources: data.primary_sources || 'No data',
                    interpretation: data.interpretation || 'No data',
                });
                setLcaData({
                    generalInformation: {
                        ReferencePCR: data.reference_pcr || 'No data',
                        DeclaredUnit: data.declared_unit || 'No data',
                        Scope: data.scope || 'No data',
                        LCA_Software: data.lca_software || 'No data',
                        LCI_Database: data.lci_database || 'No data',
                        LCIA_Methodology: data.lcia_methodology || 'No data',
                    },
                    cutOff: data.cutoff || 'No data',
                    allocationProcedure: data.allocation_procedure || 'No data',
                    primarySources: data.primary_sources || 'No data',
                    interpretation: data.interpretation || 'No data',
                    formattedReferences: [...staticReferences, ...envFactors].join('\n'), // Merged references
                });
            }
        } else {
            console.log('User ID is null. No data fetched.');
        }
    };

    useEffect(() => {

        loadLcaInfo();
    }, [userId]);
    

    const findReference = (type, category, description) => {
        const match = gwpInfo.find(info =>
            info.Type === type && info.Category === category && info.Description === description
        );
        return match ? match.Reference : 'No reference found';
    };

    const formattedEnvFactors = envFactors
        .map(factor => {
            const process = processData.find(p => p.id === factor.process_id) || {};
            const reference = findReference(factor.type, factor.category, factor.description);

            const formattedReference = `• LCA Stage: ${process.lca_stage || 'N/A'}, Process Name: ${process.name || 'N/A'}, Type: ${factor.type}, Category: ${factor.category}, Description: ${factor.description}, Reference: ${reference}`;

            return formattedReference;
        })
        .filter(Boolean)
        .join('\n');

    const formattedPrimarySources = editableLcaInfo.primarySources || 'No data';

    const updateLcaInfo = async () => {
        if (userId) {
            try {
                // Determine API URL and method based on the value of isDefaultUsed
                const apiUrl = isDefaultUsed ? `${config.apiUrl}/add_data` : `${config.apiUrl}/update_data`;
                const method = isDefaultUsed ? 'POST' : 'PUT';
    
                const body = isDefaultUsed
                    ? JSON.stringify({
                        table_name: 'LCA_info',
                        data: {
                            user_id: userId, // Include user_id when adding new data
                            reference_pcr: editableLcaInfo.referencePCR,
                            declared_unit: editableLcaInfo.declaredUnit,
                            scope: editableLcaInfo.scope,
                            lca_software: editableLcaInfo.lcaSoftware,
                            lci_database: editableLcaInfo.lciDatabase,
                            lcia_methodology: editableLcaInfo.lciaMethodology,
                            cutoff: editableLcaInfo.cutOff,
                            allocation_procedure: editableLcaInfo.allocationProcedure,
                            primary_sources: editableLcaInfo.primarySources,
                            interpretation: editableLcaInfo.interpretation,
                        }
                    })
                    : JSON.stringify({
                        table_name: 'LCA_info',
                        data: {
                            reference_pcr: editableLcaInfo.referencePCR,
                            declared_unit: editableLcaInfo.declaredUnit,
                            scope: editableLcaInfo.scope,
                            lca_software: editableLcaInfo.lcaSoftware,
                            lci_database: editableLcaInfo.lciDatabase,
                            lcia_methodology: editableLcaInfo.lciaMethodology,
                            cutoff: editableLcaInfo.cutOff,
                            allocation_procedure: editableLcaInfo.allocationProcedure,
                            primary_sources: editableLcaInfo.primarySources,
                            interpretation: editableLcaInfo.interpretation,
                        },
                        condition: `user_id = '${userId}'`  // Use condition for updating data
                    });
    
                // Make the fetch request to either add or update the data
                const response = await fetch(apiUrl, {
                    method: method,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: body
                });
    
                const result = await response.json();
    
                // Handle the result based on success or failure
                if (result.status === 'success') {
                    alert(isDefaultUsed ? 'Information added successfully!' : 'Information updated successfully!');
    
                    // After a successful update/add, re-fetch the LCA information
                    fetchLcaInfo();
                    loadLcaInfo();
                } else {
                    alert('Failed to save information.');
                }
            } catch (error) {
                console.error('Error updating/adding LCA info:', error);
                alert('Error saving information.');
            }
        } else {
            alert('User ID is null. Cannot update/add information.');
        }
    };
    
    

    return (
        <div className="lca-container">
            <div className="container-header">
                <button className="toggle-button" onClick={toggleContainer}>
                    {isOpen ? (
                        <Icon icon="iconamoon:arrow-up-2" style={{ color: 'black', fontSize: '20px' }} />
                    ) : (
                        <Icon icon="iconamoon:arrow-down-2" style={{ color: 'black', fontSize: '20px' }} />
                    )}
                </button>
                <h2>{selectedText.title || 'Life Cycle Assessment'}</h2>
            </div>
            {isOpen && (
                <>
                    <div className="section general-info-layout">
                    <div className="general-info-header">
                            <h2 className="centered-title">{selectedText.generalInformation || 'General Information'}</h2>
                            <button className="right-button" onClick={updateLcaInfo}>
                                Save Information
                            </button>
                        </div>
                        {generalInfo(selectedText.referencePCR || 'Reference PCR', editableLcaInfo.referencePCR, 'referencePCR')}
                        {generalInfo(selectedText.declaredUnit || 'Declared Unit', editableLcaInfo.declaredUnit, 'declaredUnit')}
                        {generalInfo(selectedText.scope || 'Scope', editableLcaInfo.scope, 'scope')}
                        {generalInfo(selectedText.lcaSoftware || 'LCA Software', editableLcaInfo.lcaSoftware, 'lcaSoftware')}
                        {generalInfo(selectedText.lciDatabase || 'LCI Database(s)', editableLcaInfo.lciDatabase, 'lciDatabase')}
                        {generalInfo(selectedText.lciaMethodology || 'LCIA Methodology', editableLcaInfo.lciaMethodology, 'lciaMethodology')}
                    </div>
                    <div className="section">
                        <h2>{selectedText.cutOff || 'Cut-Off'}</h2>
                        {textArea(editableLcaInfo.cutOff, 'cutOff')}
                    </div>
                    <div className="section">
                        <h2>{selectedText.allocationProcedure || 'Allocation Procedure'}</h2>
                        {textArea(editableLcaInfo.allocationProcedure, 'allocationProcedure')}
                    </div>
                    <div className="section">
                        <h2>{selectedText.primarySources || 'Primary Sources'}</h2>
                        {textArea(formattedPrimarySources, 'primarySources')}
                    </div>
                    <div className="section">
                        <h2>{selectedText.interpretation || 'Interpretation'}</h2>
                        {textArea(editableLcaInfo.interpretation, 'interpretation')}
                    </div>
                    <div className="section">
                        <h2>{selectedText.references || 'References'}</h2>
                        {textArea([...staticReferences, formattedEnvFactors].join('\n'), 'references', true)}
                    </div>
                </>
            )}
        </div>
    );
}

export default LifeCycleAssessment;
