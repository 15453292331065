import '../style/sidemenu-pages-style/Settings.css';
import React from 'react';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { translations } from '../app_components/Translation';
import Header from '../app_components/Header';
import SuppliersSettings from '../sidemenu-pages/sidemenu-components/SuppliersSettings';

function Settings({selectedLanguage}) {
    const selectedText = translations[selectedLanguage]?.Settings || {};
    console.log(selectedLanguage, selectedText);

    return (
        <div className="settings">
            <Header selectedLanguage={selectedLanguage}/>
            <div className="settings-container">
                <div className="settings-navigation">
                    <nav>
                        <ul>
                            <li><a>Collaboration Settings</a></li>
                            {/* Not yet connected */}
                        </ul>
                    </nav>
                </div>
                <div className="suppliers-settings">
                    <SuppliersSettings />
                </div>
            </div>
        </div>
    );
}

export default Settings;
