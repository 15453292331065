import React from 'react';
import '../style/components-style/AdditionalInfo.css'; // Import the CSS file
import config from '../config';

const AdditionalInfo = ({userid, isSupplier, additionalInfo, editableAdditionalInfo, handleInputChange, handleSave, pageType}) => {
    const isEditable = additionalInfo?.collaborator_id === null || userid === additionalInfo.collaborator_id || additionalInfo?.collaborator_id === '';
    // console.log('additionalInfo', additionalInfo);
    const unitValue = {
        "Raw Material (Supplier)": ["mg", "g", "kg", "ton", "oz", "lb",
            "kWh", "J", "kJ",
            "t.km",
            "s", "min", "h", "hr", "day", "wk",
            "mm3", "cm3", "dm3", "m3", "km3", "inch3", "feet3", "L", "gal", "fl_oz",
        ],
    };

    const units = unitValue["Raw Material (Supplier)"] || [];

    const areaUnits = ['m2', 'km2', 'ft2', 'yd2', 'mi2'];
    const moneyUnits = ['USD', 'CAD', 'EUR', 'GBP', 'AUD', 'JPY', 'CNY']
    const productionTypes = ['product', 'part']

    // Combine original handleSave with new functionality
    const combinedHandleSave = async () => {
        // Call the existing handleSave function
        if (handleSave) {
            handleSave();
        }

        if (pageType==='supplier') {
            // New functionality to update the database
            try {
                // Prepare the data to be sent in the API request
                const response = await fetch(`${config.apiUrl}/update_data`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        table_name: 'env_factors',
                        data: {
                            unit: editableAdditionalInfo.functional_unit,
                        },
                        condition: `category = '${additionalInfo.product}' AND description = '${additionalInfo.supplier}'`
                    }),
                });

                if (!response.ok) {
                    throw new Error('Failed to update data');
                }

                const result = await response.json();
                if (result.status !== 'success') {
                    alert('Failed to update data');
                }
            } catch (error) {
                console.error('Error updating data:', error);
                alert('Error updating data');
            }
        }
    };

    return (
        <div className="additional-information">
            <form>
                <div>
                    <label>Contact Person:</label>
                    <input
                        type="text"
                        name="detail_contact_person"
                        value={editableAdditionalInfo.detail_contact_person || ''}
                        onChange={handleInputChange}
                        readOnly={!isEditable}
                    />
                </div>
                <div>
                    <label>Email:</label>
                    <input
                        type="email"
                        name="detail_email"
                        value={editableAdditionalInfo.detail_email || ''}
                        onChange={handleInputChange}
                        readOnly={!isEditable}
                    />
                </div>
                <div>
                    <label>Phone:</label>
                    <input
                        type="tel"
                        name="detail_phone"
                        value={editableAdditionalInfo.detail_phone || ''}
                        onChange={handleInputChange}
                        readOnly={!isEditable}
                    />
                </div>
                {(pageType === "supplier" || pageType === "utilizer") && (<div>
                    <label>Address</label>
                    <input
                        type="text"
                        name="detail_address"
                        value={editableAdditionalInfo.detail_address || ''}
                        onChange={handleInputChange}
                        readOnly={!isEditable}
                    />
                </div>)}
                {!isSupplier && pageType !== "utilizer" && pageType !== "utilizer" && (<div>
                    <label>Area</label>
                    <input
                        type="text"
                        name="square_footage_area"
                        value={editableAdditionalInfo.square_footage_area || ''}
                        onChange={handleInputChange}
                        readOnly={!isEditable}
                    />
                    <label>Area Unit:</label>
                    <select
                        name="area_unit"
                        value={editableAdditionalInfo.area_unit || ''}
                        onChange={handleInputChange}
                        disabled={!isEditable}
                    >
                        <option value="">Select a unit</option>
                        {areaUnits.map(unit => (
                            <option key={unit} value={unit}>{unit}</option>
                        ))}
                    </select>
                </div>)}
                {isSupplier && (<div>
                    <label>Functional Unit:</label>
                    <select
                        name="functional_unit"
                        value={editableAdditionalInfo.functional_unit || ''}
                        onChange={handleInputChange}
                        disabled={!isEditable}
                    >
                        <option value="">Select a unit</option>
                        {units.map(unit => (
                            <option key={unit} value={unit}>{unit}</option>
                        ))}
                    </select>
                </div>)}
                {!isSupplier && pageType !== "utilizer" && pageType !== "utilizer" && (<div>
                    <label>Production Type:</label>
                    <select
                        name="production_type"
                        value={editableAdditionalInfo.production_type || ''}
                        onChange={handleInputChange}
                        disabled={!isEditable}
                    >
                        <option value="">Select a production type</option>
                        {productionTypes.map(type => (
                            <option key={type} value={type}>{type}</option>
                        ))}
                    </select>
                    <label>Production Name</label>
                    <input
                        type="text"
                        name="production_name"
                        value={editableAdditionalInfo.production_name || ''}
                        onChange={handleInputChange}
                        readOnly={!isEditable}
                    />
                </div>)}
                {!isSupplier && pageType !== "utilizer" && pageType !== "utilizer" && (
                    <div>
                        <label>Production Capacity</label>
                        <input
                            type="text"
                            name="production_capacity"
                            value={editableAdditionalInfo.production_capacity || ''}
                            onChange={handleInputChange}
                            readOnly={!isEditable}
                        />
                    </div>
                )}
                {(pageType === "supplier") && (<div>
                    <label>Specifications:</label>
                    <input
                        type="text"
                        name="specifications"
                        value={editableAdditionalInfo.specifications || ''}
                        onChange={handleInputChange}
                        readOnly={!isEditable}
                    />
                </div>)}
                {!isSupplier && pageType !== "utilizer" && pageType !== "utilizer" && (<div>
                    <label>Total Revenue</label>
                    <input
                        type="text"
                        name="total_revenu"
                        value={editableAdditionalInfo.total_revenu || ''}
                        onChange={handleInputChange}
                        readOnly={!isEditable}
                    />
                    <label>Revenue Unit:</label>
                    <select
                        name="revenu_unit"
                        value={editableAdditionalInfo.revenu_unit || ''}
                        onChange={handleInputChange}
                        disabled={!isEditable}
                    >
                        <option value="">Select a unit</option>
                        {moneyUnits.map(unit => (
                            <option key={unit} value={unit}>{unit}</option>
                        ))}
                    </select>
                </div>)}
                {(!isSupplier || pageType === "utilizer") && (
                    <div>
                        <label>Comments:</label>
                        <input
                            type="text"
                            name="comments"
                            value={editableAdditionalInfo.comments || ''}
                            onChange={handleInputChange}
                            readOnly={!isEditable}
                        />
                    </div>
                )}
                <div>
                    <label>Date Modified:</label>
                    <span>{editableAdditionalInfo.date_modified || ''}</span>
                </div>
                <button
                    type="button"
                    onClick={combinedHandleSave}
                    disabled={!isEditable}
                    className={!isEditable ? 'disabled' : ''}
                >
                    Save Additional Information
                </button>
            </form>
        </div>
    );
};

export default AdditionalInfo;
