import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../style/pages-style/Register.css';
import logo from '../assets/logo/Arivu.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../authentication/AuthProvider';
import { usePendingUser } from '../authentication/PendingUserContext';
import config from '../config';

function Register() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [recheckPassword, setRecheckPassword] = useState('');  // Added recheck password
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [company_name, setCompany_name] = useState('');
    const [email, setEmail] = useState('');
    const [lat, setLat] = useState('');
    const [lon, setLon] = useState('');
    const [isInvalid, setIsInvalid] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [passwordsMatch, setPasswordsMatch] = useState(true);  // Added state for matching passwords

    const auth = useAuth();
    const navigate = useNavigate();
    const { setPendingUser } = usePendingUser();

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (password !== recheckPassword) {
            setPasswordsMatch(false);
            setIsInvalid(false); // Ensure only one error is displayed
            return;
        }

        const user_info = {
            user_id: username,
            password: password,
            company_name: company_name,
            address: address,
            email: email,
            phone_number: phoneNumber,
            lat: lat,
            lon: lon,
        };

        try {
            const response = await fetch(`${config.apiUrl}/register_user`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(user_info),
            });

            const data = await response.json();

            if (response.ok) {
                setIsInvalid(false);
                setPendingUser(username);
                navigate('/terms_and_conditions');
            } else {
                setIsInvalid(true);
                console.error('Registration failed:', data.message);
            }
        } catch (error) {
            console.error('Network error:', error);
            setIsInvalid(true);
        }
    };

    const toggleVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    return (
        <div className="register-container">
            <div className="logo-container-arivu ">
                <img src={logo} alt="logo" style={{ height: '75px' }} />
            </div>

            <div className="divider"></div>

            {(isInvalid || !passwordsMatch) && (
                <div className="error-message">
                    {isInvalid ? 'Invalid username or password' : 'Passwords do not match'}
                </div>
            )}

            <form onSubmit={handleSubmit} className="form">
                {/* Form Fields */}
                <div className="input-group">
                    <div className="input-field">
                        <label htmlFor="company-name-input">Company Name</label>
                        <input
                            type="text"
                            id="company-name-input"
                            placeholder="Enter your company name"
                            value={company_name}
                            onChange={(e) => setCompany_name(e.target.value)}
                        />
                    </div>
                    <div className="input-field">
                        <label htmlFor="username-input">Username</label>
                        <input
                            type="text"
                            id="username-input"
                            placeholder="Enter your username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </div>
                </div>

                <div className="input-group">
                    <div className="input-field">
                        <label htmlFor="email-input">Email</label>
                        <input
                            type="email"
                            id="email-input"
                            placeholder="Enter your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="input-field">
                        <label htmlFor="phone-number-input">Phone Number</label>
                        <input
                            type="text"
                            id="phone-number-input"
                            placeholder="Enter your phone number"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                    </div>
                </div>

                <div className="input-field-address">
                    <label htmlFor="address-input">Address</label>
                    <input
                        type="text"
                        id="address-input"
                        placeholder="Enter your address"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                    />
                </div>

                <div className="input-group">
                    <div className="input-field">
                        <label htmlFor="lat-input">Latitude</label>
                        <input
                            type="text"
                            id="lat-input"
                            placeholder="Enter your latitude"
                            value={lat}
                            onChange={(e) => setLat(e.target.value)}
                        />
                    </div>
                    <div className="input-field">
                        <label htmlFor="lon-input">Longitude</label>
                        <input
                            type="text"
                            id="lon-input"
                            placeholder="Enter your longitude"
                            value={lon}
                            onChange={(e) => setLon(e.target.value)}
                        />
                    </div>
                </div>

                <div className="input-group">
                    <div className="input-field">
                        <label htmlFor="password-input">Password</label>
                        <div className="password-input-group">
                            <input
                                type={passwordVisible ? 'text' : 'password'}
                                id="password-input"
                                placeholder="Enter your password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <FontAwesomeIcon
                                icon={passwordVisible ? faEyeSlash : faEye}
                                onClick={toggleVisibility}
                                className="toggle-password-visibility-button"
                            />
                        </div>
                    </div>
                    <div className="input-field">
                        <label htmlFor="recheck-password-input">Recheck Password</label>
                        <div className="password-input-group">
                            <input
                                type={passwordVisible ? 'text' : 'password'}
                                id="recheck-password-input"
                                placeholder="Re-enter your password"
                                value={recheckPassword}
                                onChange={(e) => setRecheckPassword(e.target.value)}
                            />
                            <FontAwesomeIcon
                                icon={passwordVisible ? faEyeSlash : faEye}
                                onClick={toggleVisibility}
                                className="toggle-password-visibility-button"
                            />
                        </div>
                    </div>
                </div>
                <button className="register-button" type="submit">Register</button>

            </form>
        </div>
    );
}

export default Register;
