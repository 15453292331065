import React, { useState } from 'react';
import './style/App.css';
import Login from "./pages/Login";
import Footer from "./app_components/Footer";
import HomePage from "./pages/HomePage";
import Supplier from "./pages/Supplier";
import Utilizer from "./pages/Utilizer";
import Comparison from "./pages/Comparison";
import Support from "./sidemenu-pages/Support";
import Settings from "./sidemenu-pages/Settings";
import Profile from "./sidemenu-pages/Profile";
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import TermsAndConditions from "./pages/TermsAndConditions";
import { AuthProvider } from "./authentication/AuthProvider";
import PrivateRoute from "./authentication/PrivateRoute";
import Reuse from "./pages/Reuse";
import { PendingUserProvider } from "./authentication/PendingUserContext";
import Register from "./pages/Register";
import HomeViewType from "./pages/HomeViewType";
import HomeOrganizational from "./pages/HomeOrganizational";
import OrganizationalSupplyChain from "./organizational-pages/OrganizationalSupplyChain";
import OrganizationalOrganization from "./organizational-pages/OrganizationalOrganization";
import OrganizationalComparison from "./organizational-pages/OrganizationalComparison";

function App() {
  const [selectedLanguage, setSelectedLanguage] = useState('EN');

  return (
    <AuthProvider>
      <PendingUserProvider>
        <Router>
          <div className="App">
            <Routes>
              <Route path="/login" element={<Login selectedLanguage={selectedLanguage}/>} />
              <Route path="/register" element={<Register selectedLanguage={selectedLanguage}/>} />
              <Route path="/" element={<Navigate replace to='/login' />} />
              <Route path="/terms_and_conditions" element={<TermsAndConditions selectedLanguage={selectedLanguage}/>} />
              <Route element={<PrivateRoute />}>
                <Route path="/home" element={<HomePage selectedLanguage={selectedLanguage} />} />
                <Route path="/homeviewtype" element={<HomeViewType selectedLanguage={selectedLanguage} />} />
                <Route path="/homeorganizational" element={<HomeOrganizational selectedLanguage={selectedLanguage} />} />
                <Route path="/supplier" element={<Supplier selectedLanguage={selectedLanguage} />} />
                <Route path="/utilizer" element={<Utilizer selectedLanguage={selectedLanguage} />} />
                <Route path="/comparison" element={<Comparison selectedLanguage={selectedLanguage} />} />
                <Route path="/organizationalsupplychain" element={<OrganizationalSupplyChain selectedLanguage={selectedLanguage} />} />
                <Route path="/organizationalorganization" element={<OrganizationalOrganization selectedLanguage={selectedLanguage} />} />
                <Route path="/organizationalcomparison" element={<OrganizationalComparison selectedLanguage={selectedLanguage} />} />
                <Route path="/reuse" element={<Reuse selectedLanguage={selectedLanguage} />} />
                <Route path="/support" element={<Support selectedLanguage={selectedLanguage} />} />
                <Route path="/settings" element={<Settings selectedLanguage={selectedLanguage} />} />
                <Route path="/profile" element={<Profile selectedLanguage={selectedLanguage} />} />
              </Route>
            </Routes>
            <Footer selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />
          </div>
        </Router>
      </PendingUserProvider>
    </AuthProvider>
  );
}

export default App;
