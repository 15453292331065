import React, {useEffect, useState} from "react";
import {translations} from "../app_components/Translation";
import {Icon} from "@iconify/react";
import Grid from "../environment-page-components/Grid";
import config from "../config";
import DetailsTableButton from "./DetailsTableButton";
import {useAuth} from "../authentication/AuthProvider";
import ColumnHeaderIcon from "../app_components/ColumnHeaderIcon";

// const commonColumnHeaders = [{name: 'CO2 Emissions(kg)', type: 'float'}, {
//     name: 'CH4 Emissions(g)',
//     type: 'float'
// }, {name: 'N2O Emissions(g)', type: 'float'}, {name: 'Total Emissions (CO2 Equivalent (kg))', type: 'float'}]
//
// const stationaryCombustionColumnHeaders = [{name: 'Source ID', type: 'string'}, {
//     name: 'Source Description',
//     type: 'string'
// }, {name: 'Source Area(sq ft)', type: 'float'}, {name: 'Fuel Combusted', type: 'string'},
//     {name: 'Fuel State(solid, liquid, gas)', type: 'string'}, {
//         name: 'Quantity Combusted',
//         type: 'float'
//     }, {name: 'Units', type: 'string'}];
// const mobileSourcesColumnHeaders = [{name: 'Source ID', type: 'string'}, {
//     name: 'Source Description',
//     type: 'string'
// }, {name: 'On-Road or Non-Road?', type: 'string'}, {name: 'Vehicle Type', type: 'string'}, {
//     name: 'Vehicle Year',
//     type: 'string'
// },
//     {name: 'Fuel Usage', type: 'float'}, {name: 'Units', type: 'string'}, {name: 'Miles Traveled', type: 'float'}];
// const refrigerationACColumnHeaders = [{name: 'Gas', type: 'string'}, {
//     name: 'Gas GWP',
//     type: 'float'
// }, {name: 'Inventory Change(kg)', type: 'float'}, {
//     name: 'Transferred Amount(kg)',
//     type: 'float'
// }, {name: 'Capacity Change(kg)', type: 'float'}]
// const fireSuppressionColumnHeaders = [{name: 'Gas', type: 'string'}, {
//     name: 'Gas GWP',
//     type: 'float'
// }, {name: 'Inventory Change(kg)', type: 'float'}, {
//     name: 'Transferred Amount(lb)',
//     type: 'float'
// }, {name: 'Capacity Change(lb)', type: 'float'}];
// const purchasedGasesColumnHeaders = [{name: 'Gas', type: 'string'}, {
//     name: 'Gas GWP',
//     type: 'float'
// }, {name: 'Purchased Amount(lb)', type: 'float'}];
// const marketBasedElectricityColumnHeaders = [{name: 'Source ID', type: 'string'}, {
//     name: 'Source Description',
//     type: 'string'
// }, {name: 'Source Area(sq ft)', type: 'float'}, {name: 'eGRID Subregion', type: 'string'},
//     {name: 'Electricity Purchased(kWh)', type: 'float'}, {
//         name: 'CO2 Emissions(lb/MWh)',
//         type: 'float'
//     }, {name: 'CH4 Emissions(lb/MWh)', type: 'float'}, {name: 'N2O Emissions(lb/MWh)', type: 'float'}];
// const locationBasedElectricityColumnHeaders = [{name: 'Source ID', type: 'string'}, {
//     name: 'Source Description',
//     type: 'string'
// }, {name: 'Source Area(sq ft)', type: 'float'}, {name: 'eGRID Subregion', type: 'string'},
//     {name: 'Electricity Purchased(kWh)', type: 'float'}];
// const steamColumnHeaders = [{name: 'Source ID', type: 'string'}, {
//     name: 'Source Description',
//     type: 'string'
// }, {name: 'Source Area(sq ft)', type: 'float'}, {name: 'Fuel Type', type: 'string'}, {
//     name: 'Boiler Efficiency(%)',
//     type: 'float'
// },
//     {name: 'Steam Purchased(mmBtu)', type: 'float'}, {
//         name: 'CO2 Factor(kg/mmBtu)',
//         type: 'float'
//     }, {name: 'CH4 Factor(g/mmBtu)', type: 'float'}, {name: 'N2O Factor(g/mmBtu)', type: 'float'}];
// const businessTravelAndCommutingColumnHeaders = [{name: 'Source ID', type: 'string'}, {
//     name: 'Source Description',
//     type: 'string'
// }, {name: 'Vehicle Type', type: 'string'}, {name: 'Vehicle Miles(miles)', type: 'float'}]
// const wasteColumnHeaders = [{name: 'Source ID', type: 'string'}, {
//     name: 'Source Description',
//     type: 'string'
// }, {name: 'Waste Material', type: 'string'}, {name: 'Disposal Method', type: 'string'}, {
//     name: 'Weight',
//     type: 'float'
// }, {name: 'Unit', type: 'string'}];
//
// function formatFloatValue(value, decimalPlaces = 2) {
//     return parseFloat(value).toFixed(decimalPlaces);
// }
//
// function generateColumnDefs(columnHeaders) {
//     const specificColumnDefs = columnHeaders.map((header, index) => ({
//         headerName: header.name,
//         field: header.name.toLowerCase().replace(/[()]/g, '').replace(/[\s,]+/g, '_'),
//         cellEditor: 'agTextCellEditor',
//         editable: true,
//         valueFormatter: header.type === 'float' ? (params) => formatFloatValue(params.value, 2) : undefined,
//         checkboxSelection: index === 0,
//         headerCheckboxSelection: index === 0,
//         minWidth: 150,
//         cellStyle: {textAlign: 'left'}
//     }));
//
//     const commonColumnDefs = commonColumnHeaders.map((header) => ({
//         headerName: header.name,
//         field: header.name.toLowerCase().replace(/[()]/g, '').replace(/[\s,]+/g, '_'),
//         cellEditor: 'agTextCellEditor',
//         editable: false,
//         valueFormatter: header.type === 'float' ? (params) => formatFloatValue(params.value, 2) : undefined,
//         minWidth: 150,
//         cellStyle: {textAlign: 'left'}
//     }));
//
//     return [...specificColumnDefs, ...commonColumnDefs];
// }

const unitValue = {
    Material: ["mg", "g", "kg", "ton", "oz", "lb"],
    Energy: ["mmBTU", "kWh", "J", "kJ"],
    Transport: ["t.km"],
    Reagent: ["mg", "g", "kg", "ton", "oz", "lb"],
    "Raw material by supplier": ["CO2eq"],
};

function formatFloatValue(value, decimalPlaces) {
    return Number.parseFloat(value).toFixed(decimalPlaces);
}

function ScopeCategoryDetailsTable({
                                       selectedScope,
                                       setCategoryDetailsTableGridApi,
                                       setScopeDetailsData,
                                       setSelectedEmissionDetailsData,
                                       isSupplier,
                                       setTotalGWP,
                                       selectedLanguage
                                   }) {
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [isOpen, setIsOpen] = useState(true);
    // const [columnDefs, setColumnDefs] = useState([]);
    const [emissionDetailsData, setEmissionDetailsData] = useState([]);
    const [calculationMethods, setCalculationMethods] = useState([]);
    const [typeValues, setTypeValues] = useState([]);
    const [categoryValues, setCategoryValues] = useState([]);
    const [descriptionValues, setDescriptionValues] = useState([]);
    const {user} = useAuth();
    const userId = user ? user.username : null;
    const [supplierData, setSupplierData] = useState([]);
    const [selectedDetailsRows, setSelectedDetailsRows] = useState([]);
    const selectedText = translations[selectedLanguage].scopeCategoryDetailsTable;

    const onGridReady = params => {
        setGridApi(params.api);
        setCategoryDetailsTableGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };

    useEffect(() => {
        if (selectedScope) {
            if (selectedScope.category === 'Stationary Combustion') {
                setCalculationMethods(['Fuel based'])
            } else if (selectedScope.category === 'Mobile Sources') {
                setCalculationMethods(['Fuel based', 'Distance based'])
            } else if (selectedScope.category === 'Refrigeration / AC Equipment Use') {
                setCalculationMethods(['Material balance'])
            } else if (selectedScope.category === 'Fire Suppression') {
                setCalculationMethods(['Material balance'])
            } else if (selectedScope.category === 'Purchased Gases') {
                setCalculationMethods(['Purchased based'])
            } else if (selectedScope.category === 'Electricity') {
                setCalculationMethods(['Location based', 'Market based'])
            } else if (selectedScope.category === 'Steam') {
                setCalculationMethods(['Location based'])
            } else if (selectedScope.category === 'Business Travel') {
                setCalculationMethods(['Distance Based'])
            } else if (selectedScope.category === 'Employee Commuting') {
                setCalculationMethods(['Fuel based', 'Distance based'])
            } else if (selectedScope.category === 'Upstream Transportation and Distribution') {
                setCalculationMethods(['Fuel based', 'Distance based'])
            } else if (selectedScope.category === 'Downstream Transportation and Distribution') {
                setCalculationMethods(['Fuel based', 'Distance based'])
            } else if (selectedScope.category === 'Purchased Goods and Services') {
                setCalculationMethods(['Supplier data', 'Spend based', 'Average data'])
            } else if (selectedScope.category === 'Capital Goods') {
                setCalculationMethods(['Supplier data', 'Spend based', 'Average data'])
            } else if (selectedScope.category === 'Fuel and Energy-related Activities') {
                setCalculationMethods(['Average data'])
            } else if (selectedScope.category === 'Processing of Sold Products') {
                setCalculationMethods(['Site specific', 'Average data'])
            } else if (selectedScope.category === 'Use of Sold Products') {
                setCalculationMethods(['Direct use-phase', 'Indirect use-phase'])
            } else if (selectedScope.category === 'End-of-life Treatment of Sold Products') {
                setCalculationMethods(['Waste type specific'])
            } else if (selectedScope.category === 'Waste') {
                setCalculationMethods(['Supplier-specific', 'Waste-type-specific', 'Average-data'])
            } else if (selectedScope.category === 'Franchises') {
                setCalculationMethods(['Franchise-specific', 'Average-data'])
            } else if (selectedScope.category === 'Investments') {
                setCalculationMethods(['Investment-specific', 'Average-data'])
            } else if (selectedScope.category === 'Upstream Leased Assets') {
                setCalculationMethods(['Asset-specific', 'Lessor-specific', 'Average-data'])
            } else if (selectedScope.category === 'Downstream Leased Assets') {
                setCalculationMethods(['Asset-specific', 'Lessor-specific', 'Average-data'])
            } else {
                setCalculationMethods([])
            }
        }
    }, [selectedScope]);

    const refreshEmissionDetailsData = () => {
        if (!selectedScope || !selectedScope['id']) {
            setEmissionDetailsData([]);
            return;
        }
        const scopeTableID = selectedScope['id'];

        fetch(`${config.apiUrl}/api/get-emission-details-by-id`, {
            method: 'POST', headers: {
                'Content-Type': 'application/json',
            }, body: JSON.stringify({
                emissionID: scopeTableID
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                setScopeDetailsData(data);
                setEmissionDetailsData(data);
            })
            .catch(error => {
                console.error('Error fetching data:', error.message);
            });
    };

    const calculateTotalEmission = () => {
        return parseFloat(emissionDetailsData.reduce((total, row) => {
            if (row.totalco2eq && !isNaN(row.totalco2eq)) {
                return total + parseFloat(row.totalco2eq);
            }
            return total;
        }, 0).toFixed(2));  // Adjust the decimal places as needed
    };

    useEffect(() => {
        refreshEmissionDetailsData();
    }, [selectedScope]);

    useEffect(() => {
        if (selectedScope && emissionDetailsData.length > 0) {
            setTotalGWP(calculateTotalEmission())
        }
    }, [emissionDetailsData]);


    useEffect(() => {
        if (!gridApi) return;

        const onSelectionChanged = () => {
            const selectedNodes = gridApi.getSelectedNodes();
            const selectedData = selectedNodes.map(node => node.data);
            setSelectedDetailsRows(selectedData);
            setSelectedEmissionDetailsData(selectedData);
        };

        gridApi.addEventListener('selectionChanged', onSelectionChanged);
        return () => {
            gridApi.removeEventListener('selectionChanged', onSelectionChanged);
        };
    }, [gridApi, setSelectedDetailsRows]);

    const fetchSupplierData = () => {
        if (!userId) return;

        const tableName = 'suppliers_products';

        let condition;

        condition = `((user_id = '${userId}' AND collaborator_id IS NULL) OR (collaborator_id = '${userId}'))`;

        fetch(`${config.apiUrl}/fetch_data`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                table_name: tableName,
                attribute: '*',
                condition: condition
            })
        })
            .then(response => {
                if (!response.ok) throw new Error('Network response was not ok: ' + response.statusText);
                return response.json();
            })
            .then(data => setSupplierData(data || []))
            .catch(error => console.error('Error fetching data:', error));
    };

    useEffect(() => {
        fetchSupplierData();
    }, [gridApi]);

    useEffect(() => {
        if (selectedScope) {
            fetch(`${config.apiUrl}/get_type_dropdown_data_by_emission?emission_type=${selectedScope.category}`)
                .then(response => response.json())
                .then(data => {
                    setTypeValues(data.data || []);
                })
                .catch(error => {
                    console.error('Error fetching type data:', error);
                });
        }
    }, [selectedScope]);

    // useEffect(() => {
    //     fetch(`${config.apiUrl}/get_type_dropdown_data`)
    //         .then(response => response.json())
    //         .then(data => {
    //             if (data.status === 'success') {
    //                 if (!isSupplier) {
    //                     data.data.push('Raw Material (Supplier)');
    //                 }
    //                 setTypeValues(data.data);
    //             } else {
    //                 console.error('Failed to fetch type values:', data);
    //             }
    //         })
    //         .catch(error => {
    //             console.error('Error fetching type data:', error);
    //         });
    // }, [isSupplier]);

    const fetchCategoryValues = async (type) => {
        if (type === 'Raw material by supplier') {
            // Directly set the supplier data as category values and remove duplicates
            const supplierCategories = supplierData
                .map(supplier => supplier.supplier)
                .filter((value, index, self) => value && self.indexOf(value) === index); // Filter out empty strings and duplicates

            setCategoryValues(supplierCategories);
            return Promise.resolve();
        } else {
            try {
                const response = await fetch(`${config.apiUrl}/get_emission_category_dropdown_data?type=${type}`);
                const data = await response.json();
                setCategoryValues(data.data || []);
            } catch (error) {
                console.error('Error fetching category values:', error);
            }
        }
    };


    const fetchDescriptionValues = async (type, category) => {
        if (type === 'Raw material by supplier') {
            // Filter supplierData to find products associated with the selected category
            const descriptionValues = supplierData
                .filter(supplier => supplier.supplier === category)
                .map(supplier => supplier.product)
                .filter((value, index, self) => value && self.indexOf(value) === index); // Remove duplicates and empty values

            setDescriptionValues(descriptionValues);
            return Promise.resolve();
        } else {
            try {
                const response = await fetch(`${config.apiUrl}/get_emission_description_dropdown_data?type=${type}&category=${category}`);
                const data = await response.json();
                setDescriptionValues(data.data || []);
            } catch (error) {
                console.error('Error fetching description values:', error);
            }
        }
    };

    const fetchGWPUnit = async (type, category, description) => {
        try {
            const response = await fetch(`${config.apiUrl}/fetch-gwp-unit`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    type: type,
                    category: category,
                    description: description,
                })
            });

            const data = await response.json();
            return parseFloat(data.data);
        } catch (error) {
            console.error('Error fetching gwp unit:', error);
            throw error;
        }
    }

    const calculateEmission = async (type, category, description, quantity, factor) => {
        try {
            const response = await fetch(`${config.apiUrl}/calculate-total-emission`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    type: type,
                    category: category,
                    description: description,
                    quantity: quantity,
                    factor: factor,
                })
            });

            const data = await response.json();
            return parseFloat(data.data);  // Ensure it returns a float
        } catch (error) {
            console.error('Error fetching total emission:', error);
            throw error;
        }
    };

    const emissionUnitConvert = async (fromUnit, toUnit, type, category, description) => {
        try {
            const response = await fetch(`${config.apiUrl}/convert-emission-unit`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    fromUnit: fromUnit,
                    toUnit: toUnit,
                    type: type,
                    category: category,
                    description: description,
                })
            });

            const data = await response.json();
            return parseFloat(data.data);
        } catch (error) {
            console.error('Error converting emission unit:', error);
            throw error;
        }
    }


    const handleRowDataChanged = async (event) => {
        if (gridApi) {
            const nodes = gridApi.getRenderedNodes(); // Use getRenderedNodes or getAllNodes as needed

            for (const node of nodes) {
                if (node.data.type) {

                    // Wait for the category values to be fetched and set
                    await fetchCategoryValues(node.data.type);

                    if (node.data.category) {
                        // After setting category values, fetch description values
                        await fetchDescriptionValues(node.data.type, node.data.category);
                    }
                }
            }
        }
    };

    const cellValueChanged = async (params) => {
        if (params.colDef.field === 'type' && params.oldValue !== params.newValue) {
            await fetchCategoryValues(params.data.type);
            if (params.data.category) {
                await fetchDescriptionValues(params.data.type, params.data.category);
            }
        }
        if (params.colDef.field === 'category' && params.oldValue !== params.newValue) {
            await fetchDescriptionValues(params.data.type, params.newValue);
        }
        if (params.colDef.field === 'description') {
            params.data.gwp_unit = await fetchGWPUnit(params.data.type, params.data.category, params.newValue);
            gridApi.refreshCells({rowNodes: [params.node], force: true});
        }

        if ((params.colDef.field === 'quantity' || params.colDef.field === 'factor') && params.oldValue !== params.newValue) {
            try {
                params.data.totalco2eq = await calculateEmission(
                    params.data.type,
                    params.data.category,
                    params.data.description,
                    params.data.quantity,
                    params.data.factor,
                );

                // Refresh the cell to show the updated total_CO2 value
                gridApi.refreshCells({rowNodes: [params.node], force: true});
                setTotalGWP(calculateTotalEmission());

            } catch (error) {
                console.error('Error calculating total emission:', error);
            }
        }

        if (params.colDef.field === 'unit' && params.oldValue !== params.newValue) {
            if (params.data.type !== 'Raw material by supplier') {
                try {
                    const unitConversionMagnitude = await emissionUnitConvert(params.newValue, params.oldValue, params.data.type, params.data.category, params.data.description);

                    params.data.gwp_unit = params.data.gwp_unit / unitConversionMagnitude;
                    params.data.totalco2eq = params.data.totalco2eq / unitConversionMagnitude;

                    gridApi.refreshCells({rowNodes: [params.node], force: true});
                    setTotalGWP(calculateTotalEmission());
                } catch (error) {
                    console.error('Error converting emission unit:', error);
                }
            }
        }
    };

    useEffect(() => {
        if (gridApi) {
            gridApi.addEventListener('cellValueChanged', cellValueChanged);
            gridApi.addEventListener('rowDataUpdated', handleRowDataChanged);
            // gridApi.addEventListener('modelUpdated', handleRowDataChanged)；
        }

        return () => {
            if (gridApi) {
                gridApi.removeEventListener('cellValueChanged', cellValueChanged);
                gridApi.removeEventListener('rowDataUpdated', handleRowDataChanged);
                // gridApi.removeEventListener('modelUpdated', handleRowDataChanged);
            }
        };
    }, [gridApi, categoryValues, descriptionValues]);


    const columnDefs = [{
        headerName: selectedText.calculationMethod,
        field: 'calculation_method',
        cellEditor: 'agSelectCellEditor',
        editable: true,
        cellEditorParams: {
            values: calculationMethods,
        },
        checkboxSelection: true,
        headerCheckboxSelection: true,
        minWidth: 150,
        cellStyle: {textAlign: 'left'}
    }, {
        headerName: selectedText.type,
        field: 'type',
        cellEditor: 'agSelectCellEditor',
        editable: true,
        cellEditorParams: {
            values: typeValues,
        },
        minWidth: 150,
        cellStyle: {textAlign: 'left'}
    }, {
        headerName: selectedText.category,
        field: 'category',
        cellEditor: 'agSelectCellEditor',
        editable: true,
        cellEditorParams: {
            values: categoryValues,
        },
        minWidth: 175,
        cellStyle: {textAlign: 'left'}
    }, {
        headerName: selectedText.description,
        field: 'description',
        cellEditor: 'agSelectCellEditor',
        editable: true,
        cellEditorParams: {
            values: descriptionValues,
        },
        minWidth: 175,
        cellStyle: {textAlign: 'left'}
    }, {
        headerName: selectedText.quantity,
        field: 'quantity',
        filter: false,
        maxWidth: 100,
        cellEditor: 'agNumberCellEditor',
        editable: true,
        valueFormatter: (params) => formatFloatValue(params.value, 2),
        cellStyle: {textAlign: 'left'}
    }, {
        headerName: selectedText.unit,
        field: 'unit',
        cellEditor: 'agSelectCellEditor',
        editable: true,
        cellEditorParams: params => {
            const type = params.data.type;
            return {values: unitValue[type] || []};
        },
        minWidth: 100,
        maxWidth: 100,
        cellStyle: {textAlign: 'left'}
    }, {
        headerName: selectedText.factor,
        field: 'factor',
        headerComponent: ColumnHeaderIcon,
        filter: false,
        minWidth: 100,
        maxWidth: 100,
        cellEditor: 'agNumberCellEditor',
        editable: true,
        valueFormatter: (params) => formatFloatValue(params.value, 2),
        cellStyle: {textAlign: 'left'}
    }, {
        headerName: selectedText.gwpUnit,
        field: 'gwp_unit',
        filter: false,
        editable: false,
        valueFormatter: (params) => formatFloatValue(params.value, 2),
        minWidth: 125,
        cellStyle: {textAlign: 'left'}
    }, {
        headerName: selectedText.TotalCO2Eq,
        field: 'totalco2eq',
        filter: false,
        editable: false,
        valueFormatter: (params) => formatFloatValue(params.value, 2),
        minWidth: 125,
        cellStyle: {textAlign: 'left'}

    },]

    const envFactorGridProps = {
        columnDefs: columnDefs,
        rowData: emissionDetailsData,
        onGridReady,
    }

    const toggleContainer = () => setIsOpen(!isOpen);

    return (<div className={`env-factor-table ${isOpen ? 'expanded' : ''}`}>
        <div className="container-header">
            <button className="toggle-button" onClick={toggleContainer}>
                {isOpen ? (<Icon icon="iconamoon:arrow-up-2" style={{color: 'black', fontSize: '30px'}}/>) : (
                    <Icon icon="iconamoon:arrow-down-2" style={{color: 'black', fontSize: '30px'}}/>)}
            </button>
            <h2>{selectedText.scopeDetails}</h2>
        </div>
        {isOpen && (<div className="grid-container-env">
            <div className="grid-wrapper env-factor-grid-wrapper">
                <Grid {...envFactorGridProps} height={300}/>
            </div>
            <div className="gwp-total-env">
                <span className="gwp-total-env-text">Total: {calculateTotalEmission()}</span>
            </div>
            <div className="button-container-env">
                <DetailsTableButton selectedScope={selectedScope} selectedDetails={selectedDetailsRows}
                                    tableGridApi={gridApi} refreshData={refreshEmissionDetailsData}
                                    selectedLanguage={selectedLanguage}/>
            </div>
        </div>)}
    </div>);

}

export default ScopeCategoryDetailsTable;