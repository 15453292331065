import React from 'react';
import {Icon} from '@iconify/react';
import '../style/components-style/HomePageTab.css';
import {Link} from "react-router-dom";

function HomePageTab({icon, title, description, link}) {
    return (
        <Link to={link} style={{textDecoration: 'none'}}>
            <div className="feature-card">
                <Icon icon={icon} className="feature-icon"/>
                <h3>{title}</h3>
                <p>{description}</p>
            </div>
        </Link>
    );
}

export default HomePageTab;