import React from 'react';
import { FaQuestionCircle } from 'react-icons/fa'; // Import the icon

// Custom header component
const ColumnHeaderIcon = (props) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {/* Display the column title */}
      <span>{props.displayName}</span>
      {/* Add the imported "?" icon next to the header title */}
      <FaQuestionCircle
        style={{
          marginLeft: '5px',
          cursor: 'pointer',
          color: 'black',
        }}
        title="This is the emission factor, used to calculate the greenhouse gas emissions from this activity."
      />
    </div>
  );
};

export default ColumnHeaderIcon;