import React, { memo } from "react";
import "../style/components-style/ProcessEnvFactorButton.css";
import config from "../config";
import { translations } from "../app_components/Translation";

function ScopeTableButton({ selectedRow, selectedScopes = [], tableGridApi, product, organization, refreshData, selectedLanguage }) {

    const selectedText = translations[selectedLanguage].processEnvFactorButton;

    const createNewEmissionData = () => {
        if (product) {
            return {
                id: undefined,
                category: '',
                description: '',
                organization_id: null,
                product_id: product['id']
            };
        } else {
            return {
                id: undefined,
                category: '',
                description: '',
                organization_id: organization['id'],
                product_id: null
            };
        }
    };

    const addRow = () => {
        if (!tableGridApi) return;
        tableGridApi.applyTransaction({ add: [createNewEmissionData()] });
    };

    const deleteSelectedRow = () => {
        if (!tableGridApi || selectedScopes.length === 0) return;

        const selectedIds = selectedScopes.map(row => row.id);
        const tableName = 'emissions';

        fetch(`${config.apiUrl}/delete_row`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ table_name: tableName, selected_ids: selectedIds })
        })
            .then(response => response.json())
            .then(data => {
                console.log('Deleted:', data);
                // Remove the deleted rows from the table
                tableGridApi.applyTransaction({ remove: selectedScopes });
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    const saveTable = () => {
        const requestData = [];
        tableGridApi.forEachNode(node => requestData.push(node.data));
        console.log('requestData:', requestData);

        const endpoint = 'save_emissions';
        fetch(`${config.apiUrl}/${endpoint}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
        })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                refreshData(); // Refresh the data after saving
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    return (
        <div className="button-container">
            <div className="button-container-left">
                <button onClick={addRow} disabled={(!selectedRow)}>
                    {selectedText.addRow}
                </button>
                <button onClick={deleteSelectedRow} disabled={(selectedScopes.length === 0)}>
                    {selectedText.deleteSelectedRow}
                </button>
            </div>
            <div className="button-container-right">
                <button onClick={saveTable}>{selectedText.saveTable} </button>
            </div>
        </div>
    )
}

export default memo(ScopeTableButton);