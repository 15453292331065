import React, { createContext, useContext, useState, useEffect } from 'react';

const PendingUserContext = createContext();

export const usePendingUser = () => useContext(PendingUserContext);

export const PendingUserProvider = ({ children }) => {
    const [pendingUser, setPendingUser] = useState(null);
    const [termsAccepted, setTermsAccepted] = useState(
        () => JSON.parse(localStorage.getItem('termsAccepted')) || false
    );

    useEffect(() => {
        localStorage.setItem('termsAccepted', JSON.stringify(termsAccepted));
    }, [termsAccepted]);

    return (
        <PendingUserContext.Provider value={{ pendingUser, setPendingUser, termsAccepted, setTermsAccepted }}>
            {children}
        </PendingUserContext.Provider>
    );
};

export default PendingUserContext;
