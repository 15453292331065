import React, { useState } from 'react';
import TableComponent from "../environment-page-components/Table";
import '../style/organizational-pages-style/organizationalsupplychain.css';
import Header from "../app_components/Header";
import ScopeTable from "../organizational-page-components/ScopeTable";
import ScopeFootprint from "../organizational-page-components/ScopeFootprint";
import ScopeCategoryDetailsTable from "../organizational-page-components/ScopeCategoryDetailsTable";
import OrganizationalBottomLCA from "../organizational-page-components/OrganizationalBottomLCA";
import {translations} from "../app_components/Translation";
import {saveAs} from 'file-saver';
import {Document, Packer, Paragraph, Table, TableCell, TableRow, ImageRun} from 'docx';

function OrganizationalSupplyChain({ selectedLanguage }) {
    const [selectedRowData, setSelectedRowData] = useState([]);
    const [selectedScopeData, setSelectedScopeData] = useState([]);
    const [selectedEmissionDetailsData, setSelectedEmissionDetailsData] = useState([]);
    const [scopeData, setScopeData] = useState([]);
    const [scopeDetailsData, setScopeDetailsData] = useState([]);
    const [mainTableGridApi, setMainTableGridApi] = useState(null);
    const [scopeTableGridApi, setScopeTableGridApi] = useState(null);
    const [categoryDetailsTableGridApi, setCategoryDetailsTableGridApi] = useState(null);
    const [totalGWP, setTotalGWP] = useState(null);
    const [trackView, setTrackView] = useState(false);
    const [allTableRows, setAllTableRows] = useState([]);

    const [footprintChart, setFootprintChart] = useState(null);  // Footprint image
    const [footprintDoughnut, setFootprintDoughnut] = useState(null);  // Footprint doughnut chart

    const [LCAData, setLCAData] = useState([]);

    const selectedText = translations[selectedLanguage];

    const exportAsDocx = async () => {
        if (selectedRowData.length !== 1) {
            alert('Please select exactly one product from the product table to generate the report.');
            return;
        }
        try {
            const doc = new Document({
                sections: [
                    {
                        children: [
                            new Paragraph({
                                text: 'Supplier Product Report',
                                heading: "Heading1",
                            }),
                            new Table({
                                rows: [
                                    new TableRow({
                                        children: [
                                            new TableCell({ children: [new Paragraph('Product')] }),
                                            new TableCell({ children: [new Paragraph('Supplier')] }),
                                            new TableCell({ children: [new Paragraph('LEED Category')] }),
                                            new TableCell({ children: [new Paragraph('Sub Category')] }),
                                            new TableCell({ children: [new Paragraph('Year')] }),
                                            new TableCell({ children: [new Paragraph('Quantity')] }),
                                            new TableCell({ children: [new Paragraph('GWP Total')] }),
                                            new TableCell({ children: [new Paragraph('Cost')] }),
                                            new TableCell({ children: [new Paragraph('Cost per GWP')] }),
                                        ],
                                    }),
                                    ...selectedRowData.map(row => new TableRow({
                                        children: [
                                            new TableCell({ children: [new Paragraph(row.product || '')] }),
                                            new TableCell({ children: [new Paragraph(row.supplier || '')] }),
                                            new TableCell({ children: [new Paragraph(row.leed_category || '')] }),
                                            new TableCell({ children: [new Paragraph(row.sub_category || '')] }),
                                            new TableCell({ children: [new Paragraph(row.Year?.toString() || '')] }),
                                            new TableCell({ children: [new Paragraph(row.quantity?.toString() || '0')] }),
                                            new TableCell({ children: [new Paragraph(row.gwp_total?.toString() || '0')] }),
                                            new TableCell({ children: [new Paragraph(row.cost?.toString() || '0')] }),
                                            new TableCell({ children: [new Paragraph(row.cost_per_gwp?.toString() || '0')] }),
                                        ],
                                    })),
                                ],
                            }),
                            new Paragraph({
                                text: '', // Empty paragraph for spacing
                                spacing: { after: 300 },
                            }),
                            new Paragraph({
                                text: 'Emission Data',
                                heading: "Heading1",
                            }),
                            new Table({
                                rows: [
                                    new TableRow({
                                        children: [
                                            new TableCell({children: [new Paragraph('Category')]}),
                                            new TableCell({children: [new Paragraph('Description')]}),
                                            new TableCell({children: [new Paragraph('GWP Total (kg CO2eq)')]}),
                                        ],
                                    }),
                                    ...scopeData.map(row => new TableRow({
                                        children: [
                                            new TableCell({children: [new Paragraph(row.category || '')]}),
                                            new TableCell({children: [new Paragraph(row.description || '')]}),
                                            new TableCell({children: [new Paragraph(row.gwp?.toString() || '0')]}),
                                        ],
                                    })),
                                ],
                            }),
                            new Paragraph({
                                text: '', // Empty paragraph for spacing
                                spacing: { after: 300 },
                            }),
                            new Paragraph({
                                text: 'Environment Factors',
                                heading: "Heading1",
                            }),
                            new Paragraph({
                                text: 'Emission Details',
                                heading: "Heading1",
                            }),
                            new Table({
                                rows: [
                                    new TableRow({
                                        children: [
                                            new TableCell({children: [new Paragraph('Method')]}),
                                            new TableCell({children: [new Paragraph('Type')]}),
                                            new TableCell({children: [new Paragraph('Category')]}),
                                            new TableCell({children: [new Paragraph('Description')]}),
                                            new TableCell({children: [new Paragraph('Quantity')]}),
                                            new TableCell({children: [new Paragraph('Unit')]}),
                                            new TableCell({children: [new Paragraph('Factor')]}),
                                            new TableCell({children: [new Paragraph('CO2/Unit')]}),
                                            new TableCell({children: [new Paragraph('CH4/Unit')]}),
                                            new TableCell({children: [new Paragraph('N2O/Unit')]}),
                                            new TableCell({children: [new Paragraph('Total CO2-eq(kg)')]}),
                                        ],
                                    }),
                                    ...scopeDetailsData.map(row => new TableRow({
                                        children: [
                                            new TableCell({children: [new Paragraph(row.calculation_method || '')]}),
                                            new TableCell({children: [new Paragraph(row.type || '')]}),
                                            new TableCell({children: [new Paragraph(row.category || '')]}),
                                            new TableCell({children: [new Paragraph(row.description || '')]}),
                                            new TableCell({children: [new Paragraph(row.quantity?.toString() || '0')]}),
                                            new TableCell({children: [new Paragraph(row.unit || '')]}),
                                            new TableCell({children: [new Paragraph(row.factor?.toString() || '0')]}),
                                            new TableCell({children: [new Paragraph(row.totalco2eq?.toString() || '0')]}),
                                        ],
                                    })),
                                ],
                            }),
                            new Paragraph({
                                text: '', // Empty paragraph for spacing
                                spacing: { after: 300 },
                            }),
                            new Paragraph({
                                text: 'Footprint Doughnut Chart',
                                heading: "Heading1",
                            }),
                            footprintDoughnut ? new Paragraph({
                                children: [
                                    new ImageRun({
                                        data: footprintDoughnut,
                                        transformation: {
                                            width: 150,  // Match these with the aspect ratio
                                            height: 200,
                                        },
                                    }),
                                ],
                            }) : new Paragraph({
                                text: 'No Doughnut Chart available.',
                            }),
                            new Paragraph({
                                text: '', // Empty paragraph for spacing
                                spacing: { after: 300 },
                            }),
                            new Paragraph({
                                text: 'Footprint Bar Chart',
                                heading: "Heading1",
                            }),
                            footprintChart ? new Paragraph({
                                children: [
                                    new ImageRun({
                                        data: footprintChart,
                                        transformation: {
                                            width: 600,  // Match these with the aspect ratio
                                            height: 300,
                                        },
                                    }),
                                ],
                            }) : new Paragraph({
                                text: 'No Bar Chart available.',
                            }),
                            new Paragraph({
                                text: '', // Empty paragraph for spacing
                                spacing: { after: 300 },
                            }),
                            // LCA Data Section
                            new Paragraph({
                                text: 'Life Cycle Assessment Data',
                                heading: "Heading1",
                            }),

                            // General Information Section
                            new Paragraph({
                                text: 'General Information',
                                heading: "Heading2",
                            }),
                            LCAData.generalInformation ? new Table({
                                rows: [
                                    new TableRow({
                                        children: [
                                            new TableCell({ children: [new Paragraph('Reference PCR')] }),
                                            new TableCell({ children: [new Paragraph(LCAData.generalInformation.ReferencePCR || 'N/A')] }),
                                        ],
                                    }),
                                    new TableRow({
                                        children: [
                                            new TableCell({ children: [new Paragraph('Declared Unit')] }),
                                            new TableCell({ children: [new Paragraph(LCAData.generalInformation.DeclaredUnit || 'N/A')] }),
                                        ],
                                    }),
                                    new TableRow({
                                        children: [
                                            new TableCell({ children: [new Paragraph('Scope')] }),
                                            new TableCell({ children: [new Paragraph(LCAData.generalInformation.Scope || 'N/A')] }),
                                        ],
                                    }),
                                    new TableRow({
                                        children: [
                                            new TableCell({ children: [new Paragraph('LCA Software')] }),
                                            new TableCell({ children: [new Paragraph(LCAData.generalInformation.LCA_Software || 'N/A')] }),
                                        ],
                                    }),
                                    new TableRow({
                                        children: [
                                            new TableCell({ children: [new Paragraph('LCI Database(s)')] }),
                                            new TableCell({ children: [new Paragraph(LCAData.generalInformation.LCI_Database || 'N/A')] }),
                                        ],
                                    }),
                                    new TableRow({
                                        children: [
                                            new TableCell({ children: [new Paragraph('LCIA Methodology')] }),
                                            new TableCell({ children: [new Paragraph(LCAData.generalInformation.LCIA_Methodology || 'N/A')] }),
                                        ],
                                    }),
                                ],
                            }) : new Paragraph({
                                text: 'No General Information available.',
                            }),

                            // Cut-Off Section
                            LCAData.cutOff ? new Paragraph({
                                text: 'Cut-Off',
                                heading: "Heading2",
                            }) : null,
                            LCAData.cutOff ? new Paragraph({
                                text: `Cut-Off: ${LCAData.cutOff}`,
                            }) : null,

                            // Allocation Procedure Section
                            LCAData.allocationProcedure ? new Paragraph({
                                text: 'Allocation Procedure',
                                heading: "Heading2",
                            }) : null,
                            LCAData.allocationProcedure ? new Paragraph({
                                text: `Allocation Procedure: ${LCAData.allocationProcedure}`,
                            }) : null,

                            // Primary Sources of LCI Data Section
                            LCAData.primarySources ? new Paragraph({
                                text: 'Primary Sources of LCI Data',
                                heading: "Heading2",
                            }) : null,
                            LCAData.primarySources ? new Paragraph({
                                text: `Primary Sources: ${LCAData.primarySources}`,
                            }) : null,

                            // Interpretation Section
                            LCAData.interpretation ? new Paragraph({
                                text: 'Interpretation',
                                heading: "Heading2",
                            }) : null,
                            LCAData.interpretation ? new Paragraph({
                                text: `Interpretation: ${LCAData.interpretation}`,
                            }) : null,

                            LCAData.formattedEmissions ? new Paragraph({
                                text: 'Methodology',
                                heading: "Heading2",
                            }) : null,
                            LCAData.formattedEmissions ? new Paragraph({
                                text: `Methodology: ${LCAData.formattedEmissions}`,
                            }) : null,

                            // References Section
                            LCAData.formattedReferences ? new Paragraph({
                                text: 'References',
                                heading: "Heading2",
                            }) : null,
                            LCAData.formattedReferences ? new Paragraph({
                                text: `References: ${LCAData.formattedReferences}`,
                            }) : null,
                        ],
                    },
                ],
            });

            const blob = await Packer.toBlob(doc);
            saveAs(blob, 'Supplier_Report.docx');
        } catch (error) {
            console.error('Error generating DOCX:', error);
            alert('An error occurred while generating the DOCX file.');
        }
    };

    return (
        <div className='supplychain'>
            <div className="inventory-date-container-supplier">
                <button onClick={exportAsDocx} className="generate-report-supplier-button">
                    {selectedText.generateReport}
                </button>
            </div>
            <div className='table-div'>
                <Header selectedLanguage={selectedLanguage}/>
                <div className="main-table-container">
                    <TableComponent
                        isSupplier={true}
                        isOrganization={true}
                        setSelectedRowData={setSelectedRowData}
                        setMainTableGridApi={setMainTableGridApi}
                        setTrackViewState={setTrackView}
                        selectedLanguage={selectedLanguage}
                        setAllTableRows={setAllTableRows}
                        childTableRows={scopeData}
                    />
                </div>
                {!trackView && (
                    <>
                        <div className="process-env-container">
                            <ScopeTable
                                setSelectedScopeData={setSelectedScopeData}
                                setScopeData={setScopeData}
                                selectedRow={selectedRowData[0]}
                                setScopeTableGridApi={setScopeTableGridApi}
                                isSupplier={true}
                                totalGWP={totalGWP}
                                selectedLanguage={selectedLanguage}
                            />
                            <ScopeCategoryDetailsTable
                                selectedScope={selectedScopeData[0]}
                                setCategoryDetailsTableGridApi={setCategoryDetailsTableGridApi}
                                setScopeDetailsData={setScopeDetailsData}
                                setSelectedEmissionDetailsData={setSelectedEmissionDetailsData}
                                isSupplier={true}
                                setTotalGWP={setTotalGWP}
                                selectedLanguage={selectedLanguage}
                            />
                        </div>
                        <div className='footprint-div'>
                            <ScopeFootprint scopeData={scopeData} scopeDetailsData={scopeDetailsData}
                                            selectedLanguage={selectedLanguage} setFootprintChart={setFootprintChart}
                                            setFootprintDoughnut={setFootprintDoughnut}/>
                        </div>
                    </>
                )}
                <div className="life-cycle-assessment-div">
                    <OrganizationalBottomLCA selectedRow={selectedRowData[0]}
                                             selectedLanguage={selectedLanguage}
                                             setLCAData={setLCAData}/>
                </div>
            </div>
        </div>
    );
}

export default OrganizationalSupplyChain;
