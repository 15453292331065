import React, { memo } from "react";
import "../style/components-style/ProcessEnvFactorButton.css";
import config from "../config";
import { translations } from "../app_components/Translation";

function ProcessEnvFactorButton({
    selectedRows,
    selectedProcessesRows = [],
    allSelectedProcesses = [],
    selectedEnvFactorRows = [],
    tableGridApi,
    isForProcess,
    product,
    design,
    process,
    allRows,
    refreshData,
    selectedLanguage,
    isEditable
}) {

    const selectedText = translations[selectedLanguage].processEnvFactorButton;

    const createNewProcessData = () => {
        if (product) {
            return {
                id: undefined,
                lca_stage: '',
                name: '',
                design_id: null,
                product_id: product['id']
            };
        } else {
            return {
                id: undefined,
                lca_stage: '',
                name: '',
                design_id: design['id'],
                product_id: null
            };
        }
    };

    const createNewEnvFactorData = () => {
        return {
            type: '',
            category: '',
            description: '',
            default_unit: null,
            unit: '',
            factor: 1,
            quantity: 0,
            gwp_total: 0,
            default_gwp: 0,
            gwp_unit: 0,
            process_id: process['id']
        };
    };

    const addRow = () => {
        if (!tableGridApi || (!isForProcess && allSelectedProcesses.length !== 1)) return;
        if (isForProcess) {
            tableGridApi.applyTransaction({ add: [createNewProcessData()] });
        } else {
            tableGridApi.applyTransaction({ add: [createNewEnvFactorData()] });
        }
    };

    const deleteSelectedRow = () => {
        if (!tableGridApi || selectedProcessesRows.length === 0) return;

        const selectedIds = selectedProcessesRows.map(row => row.id);
        const tableName = isForProcess ? 'processes' : 'env_factors';

        fetch(`${config.apiUrl}/delete_row`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ table_name: tableName, selected_ids: selectedIds })
        })
            .then(response => response.json())
            .then(data => {
                console.log('Deleted:', data);
                // Remove the deleted rows from the table
                tableGridApi.applyTransaction({ remove: selectedProcessesRows });
                saveTable(); // Save the table after deleting
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    const saveTable = () => {
        const requestData = [];
        tableGridApi.forEachNode(node => requestData.push(node.data));
        console.log('requestData:', requestData);

        const endpoint = isForProcess ? 'save_processes' : 'save_env_factors';
        fetch(`${config.apiUrl}/${endpoint}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
        })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                refreshData(); // Refresh the data after saving
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    // Check if the "Add Row" button should be disabled based on product or design selection
    const isAddButtonDisabled = !product && !design;

    return (
        <div className="button-container">
            <div className="button-container-left">
                <button
                    onClick={addRow}
                    disabled={!isEditable || (!isForProcess && allSelectedProcesses.length !== 1)}
                >
                    {selectedText.addRow}
                </button>

                <button onClick={deleteSelectedRow} disabled={(selectedProcessesRows.length === 0) || !isEditable}>
                    {selectedText.deleteSelectedRow}
                </button>
            </div>
            <div className="button-container-right">
                <button onClick={saveTable} disabled={!isEditable}>{selectedText.saveTable}</button>
            </div>
        </div>
    );
}

export default memo(ProcessEnvFactorButton);
