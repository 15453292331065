// HomePage.js
import React from 'react';
import environmentIcon from '@iconify-icons/mdi/environment';
import organizationIcon from '@iconify-icons/mdi/human-queue';
import HomePageTab from '../homepage-components/HomePageTab';
import '../style/pages-style/HomePage.css';
import Header from '../app_components/Header';
import { translations } from '../app_components/Translation';
import { Environment } from 'ag-grid-community';

function HomeViewType({ selectedLanguage }) {
  const selectedText = translations[selectedLanguage];

  const features = [
    {
      icon: environmentIcon,
      title: selectedText.product,
      description: selectedText.description,
      link: '/home'
    },
    {
      icon: organizationIcon,
      title: selectedText.organizational,
      description: selectedText.description,
      link: '/homeorganizational'
    },
  ];

  return (
    <div className="home-page">
      <Header />
      {features.map((feature, index) => (
        <HomePageTab
          key={index}
          icon={feature.icon}
          title={feature.title}
          description={feature.description}
          link={feature.link}
        />
      ))}
    </div>
  );
}

export default HomeViewType;
