import React, { useState, useEffect, useCallback, useRef } from 'react';
import Header from '../app_components/Header';
import Grid from '../environment-page-components/Grid';
import '../style/pages-style/Reuse.css';
import config from "../config";
import { useAuth } from "../authentication/AuthProvider";
import { Icon } from '@iconify/react';
import { Bar } from 'react-chartjs-2';
import { Chart, BarElement, CategoryScale, LinearScale } from 'chart.js';
import { translations } from "../app_components/Translation";
import { Document, Packer, Paragraph, Table, TableRow, TableCell, ImageRun, Footer, TextRun } from 'docx';
import { saveAs } from 'file-saver'; // Import file-saver


Chart.register(BarElement, CategoryScale, LinearScale);

function Reuse({ selectedLanguage }) {
    const [isOpen, setIsOpen] = useState(true);
    const [isGraphOpen, setIsGraphOpen] = useState(false);
    const [rowData, setRowData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isSupplier, setIsSupplier] = useState(true); // Default to supplier
    const [graphData, setGraphData] = useState(null);
    const { user } = useAuth();
    const gridRef = useRef(null);
    const graphRef = useRef(null);

    const selectedText = translations[selectedLanguage]?.reuse || {};

    const columnDefsSupplier = [
        {
            headerName: selectedText.product || "Product",
            field: "product",
            checkboxSelection: true,
            headerCheckboxSelection: true,
            cellStyle: { textAlign: 'left' },
        },
        {
            headerName: selectedText.quantity || "Credit Quantity",
            field: "credit_quantity",
            editable: true,
            cellStyle: { textAlign: 'left' },
        },
        {
            headerName: selectedText.donorCredit || "Donor Credit (kg CO2eq)",
            field: "donor_credit",
            cellStyle: { textAlign: 'left' },
        },
        {
            headerName: selectedText.receiverCredit || "Receiver Credit (kg CO2eq)",
            field: "receiver_credit",
            cellStyle: { textAlign: 'left' },
        }
    ];


    const onGridReady = (params) => {
        gridRef.current = params.api;
        params.api.sizeColumnsToFit();
    };

    const toggleContainer = () => {
        setIsOpen(!isOpen);
    };

    const toggleGraphContainer = () => {
        setIsGraphOpen(!isGraphOpen);
    };

    const fetchFilteredData = useCallback(async () => {
        if (!user) {
            console.error('User is not authenticated or user ID is missing.');
            return;
        }

        console.log('Fetching data for user:', user.username);

        setIsLoading(true);
        try {
            // Step 1: Fetch the products or design installations for the user
            const response = await fetch(`${config.apiUrl}/filter-data`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ is_supplier: isSupplier, user_id: user.username })
            });

            const text = await response.text();
            if (!response.ok) {
                console.error(`Server error: ${response.status} ${response.statusText} - ${text}`);
                throw new Error(`Server error: ${response.status} ${response.statusText} - ${text}`);
            }

            const data = JSON.parse(text);
            const selectedValues = data.map(row => row.id);
            console.log('Data:', data);
            setRowData(data);

            if (selectedValues.length === 0) {
                console.warn('No products or design installations found for the current user.');
                setIsLoading(false);
                return;
            }

            // Step 2: Fetch donor and receiver credits for the selected products or designs
            const comparisonType = isSupplier ? 'Supplier' : 'Design';
            const creditsResponse = await fetch(`${config.apiUrl}/get_donor_receiver_credit?comparison_type=${comparisonType}&selected_values=${selectedValues.join(',')}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const creditsText = await creditsResponse.text();
            if (!creditsResponse.ok) {
                console.error(`Server error: ${creditsResponse.status} ${creditsResponse.statusText} - ${creditsText}`);
                throw new Error(`Server error: ${creditsResponse.status} ${creditsResponse.statusText} - ${creditsText}`);
            }

            const creditsData = JSON.parse(creditsText);
            console.log('Credits Data:', creditsData);

            // Step 3: Merge the credits data with the original rowData and calculate total credits
            const mergedData = data.map(row => {
                const id = row.id; // Adjust field name if necessary
                const quantity = row.quantity || 0;
                const creditQuantity = row.credit_quantity || 0;
                const donorCredit = creditsData[id]?.['Donor Credit'] || 0;
                const receiverCredit = creditsData[id]?.['Receiver Credit'] || 0;

                return {
                    ...row,
                    creditQuantity,
                    donor_credit: (donorCredit * creditQuantity).toFixed(2), // Multiply donor credit by quantity
                    receiver_credit: (receiverCredit * creditQuantity).toFixed(2) // Multiply receiver credit by quantity
                };
            });

            setRowData(mergedData);
            console.log('Merged data with credits:', mergedData);

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false);
        }
    }, [isSupplier, user]);

    const onCellValueChanged = (params) => {
        const { data } = params;
        console.log('Edited row data:', data);
        // You can perform any further calculations or updates here if necessary
        // For example, recalculating credits based on the updated credit_quantity:
        const donorCredit = (data.donor_credit / data.credit_quantity) || 0; // Adjust the formula if needed
        const receiverCredit = (data.receiver_credit / data.credit_quantity) || 0; // Adjust the formula if needed

        data.donor_credit = (donorCredit * data.credit_quantity).toFixed(2);
        data.receiver_credit = (receiverCredit * data.credit_quantity).toFixed(2);

        // You might need to update the rowData state to reflect the changes
        setRowData(prevData =>
            prevData.map(row => (row.id === data.id ? data : row))
        );
    };


    const generateGraph = (selectedRows) => {
        if (selectedRows.length === 0) {
            alert(selectedText.selectAtLeastOne || 'Please select at least one product or design installation.');
            return;
        }

        const x_data = selectedRows.map(row => isSupplier ? row.product : row.design_installation);
        const donor_credits = selectedRows.map(row => row.donor_credit);
        const receiver_credits = selectedRows.map(row => row.receiver_credit);

        const graphData = {
            labels: x_data,
            datasets: [
                {
                    label: selectedText.donorCredits || 'Donor Credits',
                    data: donor_credits,
                    backgroundColor: 'rgba(54, 162, 235, 0.6)',
                },
                {
                    label: selectedText.receiverCredits || 'Receiver Credits',
                    data: receiver_credits,
                    backgroundColor: 'rgba(255, 99, 132, 0.6)',
                }
            ]
        };

        console.log('Graph data:', graphData);

        setGraphData(graphData);
        setIsGraphOpen(true);
    };

    const onSelectionChanged = (params) => {
        const selectedRows = params.api.getSelectedRows();
        console.log('Selected rows:', selectedRows);
        generateGraph(selectedRows); // Generate graph when selection changes
    };

    const exportSelectedRowsAsCSV = () => {
        if (!gridRef.current) {
            alert(selectedText.gridNotReady || 'Grid is not ready');
            return;
        }

        const selectedRows = gridRef.current.getSelectedRows();
        if (selectedRows.length === 0) {
            alert(selectedText.selectAtLeastOne || 'Please select at least one product or design installation.');
            return;
        }

        const csvData = selectedRows.map(row => {
            return isSupplier ?
                [row.product, row.creditQuantity, row.donor_credit, row.receiver_credit] :
                [row.design_installation, row.creditQuantity, row.donor_credit, row.receiver_credit];
        });

        const csvContent = [
            isSupplier ?
                `${selectedText.product || 'Product'},${selectedText.quantity || 'Quantity'},${selectedText.donorCredit || 'Donor Credit (kg CO2eq)'},${selectedText.receiverCredit || 'Receiver Credit (kg CO2eq)'}` :
                `${selectedText.designInstallation || 'Design Installation'},${selectedText.quantity || 'Quantity'},${selectedText.donorCredit || 'Donor Credit (kg CO2eq)'},${selectedText.receiverCredit || 'Receiver Credit (kg CO2eq)'}`,
            ...csvData.map(e => e.join(","))
        ].join("\n");

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", selectedText.csvFileName || "reuse_data.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const exportSelectedRowsAsDocx = async () => {
        if (!gridRef.current) {
            alert(selectedText.gridNotReady || 'Grid is not ready');
            return;
        }
    
        const selectedRows = gridRef.current.getSelectedRows();
        if (selectedRows.length === 0) {
            alert(selectedText.selectAtLeastOne || 'Please select at least one product or design installation.');
            return;
        }
    
        try {
            // Create the DOCX document with a functional footer
            const doc = new Document({
                sections: [
                    {
                        children: [
                            new Paragraph({
                                text: isSupplier ? selectedText.products || 'Products' : selectedText.designInstallations || 'Design Installations',
                                heading: 'Heading1'
                            }),
                            new Table({
                                rows: [
                                    new TableRow({
                                        children: [
                                            new TableCell({ children: [new Paragraph(isSupplier ? selectedText.product || 'Product' : selectedText.designInstallation || 'Design Installation')] }),
                                            new TableCell({ children: [new Paragraph(selectedText.quantity || 'Quantity')] }),
                                            new TableCell({ children: [new Paragraph(selectedText.donorCredit || 'Donor Credit (kg CO2eq)')] }),
                                            new TableCell({ children: [new Paragraph(selectedText.receiverCredit || 'Receiver Credit (kg CO2eq)')] }),
                                        ],
                                    }),
                                    ...selectedRows.map(row => new TableRow({
                                        children: [
                                            new TableCell({ children: [new Paragraph(isSupplier ? row.product || '' : row.design_installation || '')] }),
                                            new TableCell({ children: [new Paragraph(row.creditQuantity?.toString() || '0')] }),
                                            new TableCell({ children: [new Paragraph(row.donor_credit?.toString() || '0')] }),
                                            new TableCell({ children: [new Paragraph(row.receiver_credit?.toString() || '0')] }),
                                        ],
                                    }))
                                ],
                            }),
                            new Paragraph({
                                text: '', // Empty paragraph for spacing
                                spacing: { after: 600 },
                            }),
                            new Paragraph({
                                text: selectedText.generatedGraph || 'Generated Graph',
                                heading: 'Heading2'
                            }),
                            new Paragraph({
                                children: [
                                    new ImageRun({
                                        data: await fetchGraphImage(), // Function to get the graph image
                                        transformation: {
                                            width: 600,
                                            height: 200,
                                        },
                                    }),
                                ],
                            }),
                        ],
                        footers: {
                            default: new Footer({
                                children: [
                                    new Paragraph({
                                        children: [
                                            new TextRun({
                                                text: 'Arivu.2024 | Copyright © 2024 VRTTA Green Solutions. All rights reserved.',
                                                font: 'Arial',
                                                size: 20, // font size
                                                italics: true
                                            })
                                        ],
                                        alignment: 'center', // Center-align the footer text
                                    })
                                ]
                            })
                        }
                    }
                ]
            });
    
            const blob = await Packer.toBlob(doc);
            saveAs(blob, "reuse-report.docx");
        } catch (error) {
            console.error('Error generating DOCX:', error);
            alert('An error occurred while generating the DOCX file.');
        }
    };
    
    
    const fetchGraphImage = () => {
        return new Promise((resolve) => {
            if (graphRef.current) {
                const graphImage = graphRef.current.toBase64Image();
                // Convert base64 image to Blob
                const imageData = graphImage.split(",")[1];
                const byteCharacters = atob(imageData);
                const byteArrays = [];
    
                for (let offset = 0; offset < byteCharacters.length; offset += 512) {
                    const slice = byteCharacters.slice(offset, offset + 512);
                    const byteNumbers = new Array(slice.length);
    
                    for (let i = 0; i < slice.length; i++) {
                        byteNumbers[i] = slice.charCodeAt(i);
                    }
    
                    const byteArray = new Uint8Array(byteNumbers);
                    byteArrays.push(byteArray);
                }
    
                const blob = new Blob(byteArrays, { type: 'image/png' });
                resolve(blob);
            } else {
                resolve(new Blob([])); // resolve with an empty blob if no graph is present
            }
        });
    };
    
       

    const downloadChartAsImage = (ref, filename) => {
        const chart = ref.current;
        if (chart) {
            const url = chart.toBase64Image();
            const link = document.createElement('a');
            link.href = url;
            link.download = `${filename}.png`;
            link.click();
        }
    };

    useEffect(() => {
        fetchFilteredData();
    }, [fetchFilteredData]);

    const saveUpdatedQuantities = async () => {
        if (!gridRef.current) {
            alert(selectedText.gridNotReady || 'Grid is not ready');
            return;
        }

        const allRowsData = [];
        gridRef.current.forEachNode(node => allRowsData.push(node.data));

        try {
            // Loop through each row and send an update request
            for (const row of allRowsData) {
                const response = await fetch(`${config.apiUrl}/update_data`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        table_name: 'suppliers_products',
                        data: {
                            credit_quantity: row.credit_quantity,
                            id: row.id
                        },
                        condition: `id = '${row.id}'`
                    }),
                });

                if (!response.ok) {
                    const text = await response.text();
                    console.error(`Server error: ${response.status} ${response.statusText} - ${text}`);
                    throw new Error(`Server error: ${response.status} ${response.statusText} - ${text}`);
                }
            }

            // Re-fetch data after successful save
            await fetchFilteredData();

            alert(selectedText.saveSuccess || 'Data saved successfully!');
        } catch (error) {
            console.error('Error saving data:', error);
            alert(selectedText.saveError || 'There was an error saving the data.');
        }
    };

    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString(
        selectedLanguage === 'FR' ? 'fr-FR' : 'en-US',
        { year: 'numeric', month: 'long', day: 'numeric' }
    );


    return (
        <div className="reuse">
            <Header selectedLanguage={selectedLanguage} />

            {/* Inventory Date and Export as DOCX Button Container */}
            <div className="inventory-date-container">
                <div className="inventory-date-reuse">
                    <h3>{selectedText.inventoryAsOf || 'Inventory as of'} {formattedDate}</h3>
                </div>
                <button onClick={exportSelectedRowsAsDocx} className="generate-report-reuse-button">
                    {selectedText.exportDocx || 'Generate Report'}
                </button>
            </div>

            <div className="grid-container-reuse">
                <div className="container-header">
                    <button className="toggle-button" onClick={toggleContainer}>
                        {isOpen ? (
                            <Icon icon="iconamoon:arrow-up-2" style={{ color: 'black', fontSize: '30px' }} />
                        ) : (
                            <Icon icon="iconamoon:arrow-down-2" style={{ color: 'black', fontSize: '30px' }} />
                        )}
                    </button>
                    <h3>{isSupplier ? selectedText.products || 'Products' : selectedText.designInstallations || 'Design Installations'}</h3>
                </div>
                {isOpen && !isLoading && (
                    <div>
                        <Grid
                            columnDefs={columnDefsSupplier}
                            rowData={rowData}
                            onGridReady={onGridReady}
                            onSelectionChanged={onSelectionChanged}
                            onCellValueChanged={onCellValueChanged}
                            className="custom-grid-class"
                        />
                        <div className="button-container-reuse">
                            <button onClick={exportSelectedRowsAsCSV}>{selectedText.exportAsCsv || 'Export as CSV'}</button>
                            <button onClick={saveUpdatedQuantities}>{selectedText.saveChanges || 'Save Changes'}</button>
                        </div>
                    </div>
                )}
                {isLoading && <p>{selectedText.loadingData || 'Loading data...'}</p>}
            </div>
            {graphData && (
                <div className="reuse-graph-container">
                    <div className="container-header">
                        <button className="toggle-button" onClick={toggleGraphContainer}>
                            {isGraphOpen ? (
                                <Icon icon="iconamoon:arrow-up-2" style={{ color: 'black', fontSize: '30px' }} />
                            ) : (
                                <Icon icon="iconamoon:arrow-down-2" style={{ color: 'black', fontSize: '30px' }} />
                            )}
                        </button>
                        <h3>{selectedText.generatedGraph || 'Generated Graph'}</h3>
                        <div className="download-icon" onClick={() => downloadChartAsImage(graphRef, 'reuse_graph')}>
                            <Icon icon="fe:download" style={{ color: 'black', cursor: 'pointer' }} />
                        </div>
                    </div>
                    {isGraphOpen && (
                        <Bar
                            ref={graphRef}
                            data={graphData}
                            options={{
                                scales: {
                                    x: {
                                        type: 'category',
                                        title: {
                                            display: true,
                                            text: isSupplier ? selectedText.product || 'Product' : selectedText.designInstallation || 'Design Installation'
                                        }
                                    },
                                    y: {
                                        beginAtZero: true,
                                        title: {
                                            display: true,
                                            text: selectedText.credits || 'Credits (kg CO2eq)'
                                        }
                                    }
                                }
                            }}
                            height={100}
                            width={300}
                        />
                    )}
                </div>
            )}
        </div>
    );

}

export default Reuse;
