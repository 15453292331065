import React, { memo, useEffect, useState } from "react";
import '../style/components-style/EnvFactorTable.css';
import config from "../config";
import Grid from "./Grid";
import ProcessEnvFactorButton from "../environment-page-components/ProcessEnvFactorButton";
import { Icon } from '@iconify/react';
import { translations } from "../app_components/Translation";
import { useAuth } from "../authentication/AuthProvider";

function EnvFactorTable({ setSelectedEnvFactorData, selectedProcess, allSelectedProcesses, selectedRow, setEnvFactorTableGridApi, isSupplier, selectedLanguage, setAllEnvFactorRows }) {
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [envFactorRowData, setEnvFactorRowData] = useState([]);
    const [typeValues, setTypeValues] = useState([]);
    const [categoryValues, setCategoryValues] = useState([]);
    const [descriptionValues, setDescriptionValues] = useState([]);
    const [unitOptions, setUnitOptions] = useState([]); 
    const [isOpen, setIsOpen] = useState(true);
    const [selectedEnvFactorRows, setSelectedEnvFactorRows] = useState([]);
    const [allRows, setAllRows] = useState([]);
    const selectedText = translations[selectedLanguage].envFactorTable;
    const { user } = useAuth();
    const userId = user ? user.username : null;
    const [supplierData, setSupplierData] = useState([]);
    const [totalGWP, setTotalGWP] = useState(0); // Add state to track total GWP
    const isEditable = selectedRow && (selectedRow.collaborator_id === '' || selectedRow.collaborator_id == null);

    const distanceUnits = ["mm", "cm", "dm", "m", "km", "inch", "ft", "mi", "nmi", "yd"];
    const areaUnits = ["mm2", "cm2", "dm2", "m2", "km2", "inch2", "ft2"];
    const volumeUnits = ["mm3", "cm3", "dm3", "m3", "km3", "L", "ml", "kl", "gal", "fl_oz", "inch3", "ft3", "bbl"];
    const weightUnits = ["mg", "g", "kg", "ton", "oz", "lb"];
    const timeUnits = ["s", "min", "h", "d", "wk", "mo", "yr"];
    const transportUnits = ["t.km", "t.mi", "t.nmi"];
    const energyUnits = ["J", "kJ", "GJ", "kWh", "Btu", "mmBTU"];
    const itemUnits = ["unit"];
    
    const unitValue = {
        // Distance units
        mm: distanceUnits,
        km: distanceUnits,
    
        // Area units
        mm2: areaUnits,
        m2: areaUnits,
    
        // Volume units
        m3: volumeUnits,
        L: volumeUnits,
        liter: volumeUnits,
    
        // Weight units
        kg: weightUnits,
        ton: weightUnits,
    
        // Time units
        day: timeUnits,
        hr: timeUnits,
    
        // Transport units
        "t.km": transportUnits,
    
        // Energy units
        J: energyUnits,
        kWh: energyUnits,
    
        // Item units
        unit: itemUnits,
    };
    
    

      // Function to fetch unit values and store them in state
    const fetchUnitValues = async (type, category, description) => {
        const condition = `
            type = '${type}' AND 
            category = '${category}' AND 
            description = '${description}'
        `;

        try {
            const response = await fetch(`${config.apiUrl}/fetch_data`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    table_name: "GWP_info",
                    attribute: "*",
                    condition: condition.trim(),
                }),
            });

            if (!response.ok) {
                throw new Error("Network response was not ok: " + response.statusText);
            }

            const data = await response.json();
            if (data.length > 0) {
                let unitData = data[0].Unit.trim(); // Fetch the Unit from the first matching row
                const correspondingUnits = unitValue[unitData] || [];
                console.log("Fetched Units:", correspondingUnits);
                setUnitOptions(correspondingUnits); // Update state with fetched units
                return correspondingUnits;
            } else {
                console.log("No matching data found.");
                return [];
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            return [];
        }
    };

    console.log('allSelectedProcesses', allSelectedProcesses);
    console.log('selectedRow', selectedRow);
    console.log('selectedEnvFactorRows', selectedEnvFactorRows);
    console.log('allRows', allRows);

    // Handle when the cell is clicked or editing starts
    const handleCellClicked = async (params) => {
        const { type, category, description } = params.data;
        const fetchedUnits = await fetchUnitValues(type, category, description);
        setUnitOptions(fetchedUnits); // Store units in state for use in cellEditorParams
    };

    const onGridReady = params => {
        setGridApi(params.api);
        setEnvFactorTableGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };

    const fetchEnvFactorData = () => {
        if (!allSelectedProcesses || allSelectedProcesses.length === 0) {
            setEnvFactorRowData([]);
            return;
        }
    
        const processIDs = allSelectedProcesses.map(process => process['id']);
        const fetchPromises = processIDs.map(processID => {
            const url = new URL(`${config.apiUrl}/api/get-env-factor-by-process-id`);
            url.searchParams.append('process_id', processID);
    
            return fetch(url.toString(), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then(async response => {
                console.log('Response:', response);
                
                // Check if the response is not OK
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
    
                const data = await response.json();
    
                // Check for the custom message indicating no environmental factors found
                if (data.message && data.message === 'No environmental factors found for the provided process ID') {
                    console.warn(`No environmental factors found for process ID: ${processID}`);
                    return []; // Return an empty array if no factors found
                }
    
                return data; // Return the actual data if found
            });
        });
    
        Promise.all(fetchPromises)
            .then(results => {
                const combinedData = results.flat();
                setEnvFactorRowData(combinedData);
            })
            .catch(error => {
                console.error('Error fetching data:', error.message);
            });
    };
    

    useEffect(() => {
        fetchEnvFactorData();
    }, [allSelectedProcesses]);

    useEffect(() => {
        if (!gridApi) return;

        const onSelectionChanged = () => {
            const selectedNodes = gridApi.getSelectedNodes();
            const selectedData = selectedNodes.map(node => node.data);
            setSelectedEnvFactorData(selectedData);
            setSelectedEnvFactorRows(selectedData);
        };

        gridApi.addEventListener('selectionChanged', onSelectionChanged);
        return () => {
            gridApi.removeEventListener('selectionChanged', onSelectionChanged);
        };
    }, [gridApi, setSelectedEnvFactorData]);

    useEffect(() => {
        setAllRows(envFactorRowData);
        setAllEnvFactorRows(envFactorRowData);
    }, [envFactorRowData])

    function formatFloatValue(value, decimalPlaces) {
        return Number.parseFloat(value).toFixed(decimalPlaces);
    }

    useEffect(() => {
        fetch(`${config.apiUrl}/get_type_dropdown_data`)
            .then(response => response.json())
            .then(data => {
                if (data.status === 'success') {
                    if (!isSupplier) {
                        data.data.push('Raw Material (Supplier)');
                    }
                    setTypeValues(data.data);
                } else {
                    console.error('Failed to fetch type values:', data);
                }
            })
            .catch(error => {
                console.error('Error fetching type data:', error);
            });
    }, [isSupplier]);

    const fetchCategoryValues = (type) => {
        if (type === 'Raw Material (Supplier)') {
            // Directly set the supplier data as category values and remove duplicates
            const supplierCategories = supplierData
                .map(supplier => supplier.supplier)
                .filter((value, index, self) => value && self.indexOf(value) === index); // Filter out empty strings and duplicates

            setCategoryValues(supplierCategories);
            console.log('Supplier Categories:', supplierCategories);
            console.log('Supplier Data:', supplierData);
        } else {
            // Fetch the category values from the API as before
            fetch(`${config.apiUrl}/get_category_dropdown_data?type=${type}`)
                .then(response => response.json())
                .then(data => {
                    setCategoryValues(data.data || []);
                })
                .catch(error => console.error('Error fetching category values:', error));
        }
    };


    const fetchDescriptionValues = (type, category) => {
        if (type === 'Raw Material (Supplier)') {
            // Filter supplierData to find products associated with the selected category
            const descriptionValues = supplierData
                .filter(supplier => supplier.supplier === category)
                .map(supplier => supplier.product)
                .filter(value => value); // Remove only empty values
    
            setDescriptionValues(descriptionValues);
        } else {
            // Fetch the description values from the API as before
            fetch(`${config.apiUrl}/get_description_dropdown_data?type=${type}&category=${category}`)
                .then(response => response.json())
                .then(data => {
                    setDescriptionValues(data.data || []);
                })
                .catch(error => console.error('Error fetching category values:', error));
        }
    };
    

    const handleRowDataChanged = (event) => {
        if (gridApi) {
            gridApi.forEachNode((node) => {
                if (node.data.type) {
                    fetchCategoryValues(node.data.type);
                    if (node.data.category) {
                        fetchDescriptionValues(node.data.type, node.data.category);
                    }
                }
            });
        }
    };

    const cellValueChanged = (params) => {
        const { colDef, oldValue, newValue, data } = params;

        // If the type changes, fetch the new category values
        if (colDef.field === 'type' && oldValue !== newValue) {
            fetchCategoryValues(newValue);
            data.category = null; // Reset category if type changes
            data.description = null; // Reset description if type changes
            data.unit = null; // Reset unit if type changes
        }

        // If the category changes, fetch the new description values
        if (colDef.field === 'category' && oldValue !== newValue) {
            fetchDescriptionValues(data.type, newValue);
            data.description = null; // Reset description if category changes
            data.unit = null; // Reset unit if category changes
        }

        // Recalculate gwp_total when quantity, factor, or gwp_unit is changed
        if (['quantity', 'factor', 'gwp_unit'].includes(colDef.field)) {
            if (data.quantity && data.factor && data.gwp_unit) {
                const value = data.quantity * data.factor * data.gwp_unit;
                data.gwp_total = value < 0.0001 ? value.toExponential(2) : formatFloatValue(value, 2);
            } else {
                data.gwp_total = 'N/A';
            }

            // Refresh the row to apply the changes
            gridApi.refreshCells({ rowNodes: [params.node], force: true });
        }

        // If the description changes and type is 'Raw Material (Supplier)', set the corresponding unit
        if (colDef.field === 'description' && data.type === 'Raw Material (Supplier)') {
            const selectedProduct = newValue;
            const matchingSupplier = supplierData.find(supplier => supplier.product === selectedProduct);

            if (matchingSupplier) {
                // Automatically set the unit to the functional unit from supplier data
                data.unit = matchingSupplier.functional_unit;
                data.gwp_unit = matchingSupplier.gwp_total;

                // Refresh the row to apply the changes
                gridApi.refreshCells({ rowNodes: [params.node], force: true });
            }
        }

        // After updating the row, recalculate the total GWP
        const updatedTotalGWP = calculateTotalGWP();
        console.log("Updated total GWP:", updatedTotalGWP);
    };


    useEffect(() => {
        if (gridApi) {
            gridApi.addEventListener('cellValueChanged', cellValueChanged);
            gridApi.addEventListener('rowDataUpdated', handleRowDataChanged);
            gridApi.addEventListener('modelUpdated', handleRowDataChanged);
        }

        return () => {
            if (gridApi) {
                gridApi.removeEventListener('cellValueChanged', cellValueChanged);
                gridApi.removeEventListener('rowDataUpdated', handleRowDataChanged);
                gridApi.removeEventListener('modelUpdated', handleRowDataChanged);
            }
        };
    }, [gridApi, categoryValues, descriptionValues]);

    // Trigger fetchSupplierData on component mount
    useEffect(() => {
        fetchSupplierData();
    }, []); // Empty dependency array means this effect runs once on mount

    useEffect(() => {
        console.log('Supplier Data:', supplierData);
    }, [supplierData]);

    const envFactorGridProps = {
        columnDefs: [
            {
                headerName: selectedText.type,
                field: 'type',
                cellEditor: 'agSelectCellEditor',
                editable: isEditable,
                cellEditorParams: {
                    values: typeValues,
                },
                checkboxSelection: true,
                headerCheckboxSelection: true,
                minWidth: 145,
                cellStyle: { textAlign: 'left' },
                onCellClicked: (params) => {
                    fetchCategoryValues(params.data.type);
                    fetchDescriptionValues(params.data.type, params.data.category);
                },
            },
            {
                headerName: selectedText.category,
                field: 'category',
                cellEditor: 'agSelectCellEditor',
                editable: isEditable,
                cellEditorParams: (params) => {
                    // Fetch category values based on the currently selected type when the category cell is focused
                    fetchCategoryValues(params.data.type);
                    return {
                        values: categoryValues, // The updated category values based on type
                    };
                },
                minWidth: 165,
                cellStyle: { textAlign: 'left' },
                onCellClicked: (params) => {
                    fetchCategoryValues(params.data.type);
                    fetchDescriptionValues(params.data.type, params.data.category);
                },
            },
            {
                headerName: selectedText.description,
                field: 'description',
                cellEditor: 'agSelectCellEditor',
                editable: isEditable,
                cellEditorParams: (params) => {
                    fetchCategoryValues(params.data.type); // Ensure category values are loaded
                    fetchDescriptionValues(params.data.type, params.data.category); // Load descriptions for current row
                    return {
                        values: descriptionValues, // Updated description values
                    };
                },
                minWidth: 175,
                cellStyle: { textAlign: 'left' },
                onCellEditingStarted: (params) => {
                    fetchDescriptionValues(params.data.type, params.data.category);
                },
            },
            {
                headerName: selectedText.unit,
                field: 'unit',
                cellEditor: 'agSelectCellEditor',
                editable: (params) => {
                    // Make the unit field non-editable if the type is "Raw Material (Supplier)"
                    if (params.data.type === "Raw Material (Supplier)") {
                        return false;
                    }
                    return isEditable; // Otherwise, use the existing logic for editability
                },
                cellEditorParams: params => {
                    const type = params.data.type;
                    if (type === "Raw Material (Supplier)") {
                        // Get the functional unit from the supplier data
                        const matchingSupplier = supplierData.find(supplier => supplier.supplier === params.data.category && supplier.product === params.data.description);
                        let units = unitValue["Raw Material (Supplier)"]; // Default units for Raw Material (Supplier)
                        if (matchingSupplier) {
                            units = [matchingSupplier.functional_unit, ...units]; // Prepend the functional unit to the default units
                        }
                        return { values: units };
                    } else {   
                        // Use fetched unit options from state
                        const units = unitOptions.length > 0 ? unitOptions : ['N/A'];
                        console.log("Units passed to cell editor:", units);
                        return {
                            values: units,
                        };
                    }
                },
                onCellClicked: handleCellClicked, // Fetch units on cell click
                minWidth: 80,
                maxWidth: 100,
            },                    
            {
                headerName: selectedText.quantity,
                field: 'quantity',
                filter: false,
                maxWidth: 100,
                cellEditor: 'agNumberCellEditor',
                editable: isEditable,
                valueFormatter: (params) => formatFloatValue(params.value, 2),
                cellStyle: { textAlign: 'left' },
                onCellClicked: (params) => {
                    fetchCategoryValues(params.data.type);
                    fetchDescriptionValues(params.data.type, params.data.category);
                },
            },
            {
                headerName: selectedText.factor,
                field: 'factor',
                filter: false,
                minWidth: 80,
                maxWidth: 100,
                cellEditor: 'agNumberCellEditor',
                editable: isEditable,
                valueFormatter: (params) => formatFloatValue(params.value, 2),
                cellStyle: { textAlign: 'left' },
                onCellClicked: (params) => {
                    fetchCategoryValues(params.data.type);
                    fetchDescriptionValues(params.data.type, params.data.category);
                },
            },
            {
                headerName: selectedText.gwpUnit,
                field: 'gwp_unit',
                filter: false,
                editable: false,
                valueFormatter: (params) => {
                    const value = params.value;
                    if (value !== null && value !== undefined) {
                        if (value < 0.001) {
                            // Format the value in scientific notation if it's less than 0.001
                            return value.toExponential(3);
                        } else {
                            // Otherwise, format it as a float with 3 decimal places
                            return formatFloatValue(value, 3);
                        }
                    }
                    return value;
                },
                minWidth: 125,
                cellStyle: { textAlign: 'left' },
                onCellClicked: (params) => {
                    fetchCategoryValues(params.data.type);
                    fetchDescriptionValues(params.data.type, params.data.category);
                },
            },
            {
                headerName: selectedText.gwpTotal,
                field: 'gwp_total',
                filter: false,
                editable: false,
                valueGetter: (params) => {
                    if (params.data.quantity && params.data.factor && params.data.gwp_unit) {
                        const value = params.data.quantity * params.data.factor * params.data.gwp_unit;
                        return value < 0.0001 ? value.toExponential(2) : formatFloatValue(value, 2);
                    } else {
                        return 'N/A';
                    }
                },
                minWidth: 125,
                cellStyle: { textAlign: 'left' },
                onCellClicked: (params) => {
                    fetchCategoryValues(params.data.type);
                    fetchDescriptionValues(params.data.type, params.data.category);
                },
            },
            { field: 'id', hide: true },
            { field: 'process_id', hide: true },
            { field: 'default_unit', hide: true },
            { field: 'default_gwp', hide: true }
        ],
        rowData: envFactorRowData,
        onGridReady
    }

    const toggleContainer = () => setIsOpen(!isOpen);

    const fetchSupplierData = () => {
        if (!userId) return;

        const tableName = 'suppliers_products';

        let condition;

        condition = `((user_id = '${userId}' AND (collaborator_id IS NULL OR collaborator_id = '')) OR (collaborator_id = '${userId}'))`;

        fetch(`${config.apiUrl}/fetch_data`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                table_name: tableName,
                attribute: '*',
                condition: condition
            })
        })
            .then(response => {
                if (!response.ok) throw new Error('Network response was not ok: ' + response.statusText);
                console.log('Supplier data response:', response);
                return response.json();
            })
            .then(data => setSupplierData(data || []))
            .catch(error => console.error('Error fetching data:', error));
    };

    // Function to calculate total GWP
    const calculateTotalGWP = () => {
        const total = envFactorRowData.reduce((total, row) => {
            // Handle null, empty string, or NaN values by treating them as 0
            const gwpTotal = (row.gwp_total && !isNaN(row.gwp_total)) ? parseFloat(row.gwp_total) : 0;
            return total + gwpTotal;
        }, 0).toFixed(2);

        setTotalGWP(total); // Update state with the new total GWP
    };
    useEffect(() => {
        calculateTotalGWP();
    }, [envFactorRowData]);  // Recalculate whenever envFactorRowData changes




    return (
        <div className={`env-factor-table ${isOpen ? 'expanded' : ''}`}>
            <div className="container-header">
                <button className="toggle-button" onClick={toggleContainer}>
                    {isOpen ? (
                        <Icon icon="iconamoon:arrow-up-2" style={{ color: 'black', fontSize: '20px' }} />
                    ) : (
                        <Icon icon="iconamoon:arrow-down-2" style={{ color: 'black', fontSize: '20px' }} />
                    )}
                </button>
                <h2>{selectedText.envFactors}</h2>
            </div>
            {isOpen && (
                <div className="grid-container-env">
                    <div className="grid-wrapper env-factor-grid-wrapper">
                        <Grid {...envFactorGridProps} height={260} />
                    </div>
                    <div className="gwp-total-env">
                        <span className="gwp-total-env-text">Total: {totalGWP}</span>
                    </div>
                    <div className="button-container-env">
                        <ProcessEnvFactorButton
                            selectedRows={envFactorRowData} // The rows selected in the env factor table
                            selectedProcessesRows={selectedEnvFactorRows} // The specific rows in the selected process
                            allSelectedProcesses={allSelectedProcesses} // All processes being selected
                            tableGridApi={gridApi} // The API for interacting with the table grid
                            isForProcess={false} // This is for environmental factors, not processes
                            product={null} // No product is passed here
                            design={null} // No design is passed here
                            process={selectedProcess} // The selected process is passed
                            allRows={allRows} // Passing allRows to ProcessEnvFactorButton
                            refreshData={fetchEnvFactorData} // Function to refresh environmental factor data
                            selectedLanguage={selectedLanguage} // The selected language
                            isEditable={isEditable} // Editable if selectedRow is not a collaborator's row
                        />

                    </div>
                </div>
            )}
        </div>
    );

}

export default memo(EnvFactorTable);
