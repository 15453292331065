import "../../style/sidemenu-pages-style/SuppliersSettings.css";
import React, { useState, useEffect } from "react";
import { useAuth } from "../../authentication/AuthProvider";
import config from "../../config";

function SuppliersSettings({ selectedLanguage }) {
  const { user } = useAuth();
  const userId = user ? user.username : null;

  const [users, setUsers] = useState([]);
  const [currentSuppliers, setCurrentSuppliers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [supplierDetails, setSupplierDetails] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [utilizers, setUtilizers] = useState([]);
  const [utilizerDetails, setUtilizerDetails] = useState([]);

  // Fetch users, suppliers, and utilizers

  useEffect(() => {
    fetch(`${config.apiUrl}/fetch_data`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        table_name: "users",
        attribute: "user_id",
      }),
    })
      .then((response) => response.json())
      .then((userIds) => {
        if (userIds.length > 0) {
          Promise.all(
            userIds.map((id) =>
              fetch(`${config.apiUrl}/fetch_data`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  table_name: "users",
                  attributes: ["user_id", "company_name"],
                  condition: `user_id = '${id}'`,
                }),
              })
                .then((response) => response.json())
                .then((data) => data[0] || {})
            )
          )
            .then((details) => {
              const detailsMap = details.reduce((acc, detail) => {
                acc[detail.user_id] = detail;
                return acc;
              }, {});
              setUserDetails(detailsMap);
              const filteredUsers = userIds.filter(
                (user) => user !== userId && !currentSuppliers.includes(user)
              );
              setUsers(filteredUsers);
            })
            .catch((error) => {
              console.error("There was an error fetching the users!", error);
            });
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the users!", error);
      });
  }, [currentSuppliers, userId]);

  useEffect(() => {
    if (userId) {
      fetch(`${config.apiUrl}/fetch_data`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          table_name: "users",
          attribute: "suppliers_list",
          condition: `user_id = '${userId}'`,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          const suppliersListString = data[0] ? data[0] : "[]";
          const suppliersList = JSON.parse(suppliersListString);
          setCurrentSuppliers(suppliersList);

          if (suppliersList.length > 0) {
            Promise.all(
              suppliersList.map((supplierId) =>
                fetch(`${config.apiUrl}/fetch_data`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    table_name: "users",
                    attributes: [
                      "user_id",
                      "company_name",
                      "email",
                      "phone_number",
                    ],
                    condition: `user_id = '${supplierId}'`,
                  }),
                })
                  .then((response) => response.json())
                  .then((data) => data[0] || {})
              )
            )
              .then((details) => setSupplierDetails(details))
              .catch((error) =>
                console.error("Error fetching supplier details:", error)
              );
          }
        })
        .catch((error) => {
          console.error("There was an error fetching the suppliers!", error);
        });
    }
  }, [userId]);


  // fetching utilizers
  useEffect(() => {
    if (userId) {
      fetch(`${config.apiUrl}/fetch_data`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          table_name: "users",
          attributes: ["user_id", "company_name", "email", "phone_number"],
          condition: `JSON_CONTAINS(suppliers_list, '"${userId}"')`,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setUtilizerDetails(data);
          const utilizerIds = data.map((utilizer) => utilizer.user_id);
          setUtilizers(utilizerIds);
        })
        .catch((error) => {
          console.error("There was an error fetching the utilizers!", error);
        });
    }
  }, [userId]);

  const handleAddSupplier = () => {
    if (selectedUser) {
      const updatedSuppliers = [...currentSuppliers, selectedUser];
      setCurrentSuppliers(updatedSuppliers);

      fetch(`${config.apiUrl}/update_data`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          table_name: "users",
          data: {
            suppliers_list: JSON.stringify(updatedSuppliers),
          },
          condition: `user_id = '${userId}'`,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to update supplier list");
          }
          console.log("Supplier list updated successfully");

          fetch(`${config.apiUrl}/fetch_data`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              table_name: "users",
              attribute: "suppliers_list",
              condition: `user_id = '${userId}'`,
            }),
          })
            .then((response) => response.json())
            .then((data) => {
              const suppliersListString = data[0] ? data[0] : "[]";
              const suppliersList = JSON.parse(suppliersListString);
              setCurrentSuppliers(suppliersList);

              if (suppliersList.length > 0) {
                Promise.all(
                  suppliersList.map((supplierId) =>
                    fetch(`${config.apiUrl}/fetch_data`, {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({
                        table_name: "users",
                        attributes: [
                          "user_id",
                          "company_name",
                          "email",
                          "phone_number",
                        ],
                        condition: `user_id = '${supplierId}'`,
                      }),
                    })
                      .then((response) => response.json())
                      .then((data) => data[0] || {})
                  )
                )
                  .then((details) => setSupplierDetails(details))
                  .catch((error) =>
                    console.error("Error fetching supplier details:", error)
                  );
              }
            })
            .catch((error) => {
              console.error(
                "There was an error fetching the suppliers!",
                error
              );
            });
        })
        .catch((error) => {
          console.error(
            "There was an error updating the supplier list!",
            error
          );
        });
    }
  };

  return (
    <div className="suppliers-settings">
      <div className="container">
        <div className="header-and-controls">
          <div className="header-text">
            <h3>Your Suppliers</h3>
            <p>List of current collaborating suppliers</p>
          </div>
          <div className="dropdown-container">
            <select
              className="user-dropdown"
              value={selectedUser}
              onChange={(e) => setSelectedUser(e.target.value)}
            >
              <option value="" disabled>
                Select a supplier
              </option>
              {users.map((user) => {
                const userDetail = userDetails[user] || {};
                return (
                  <option key={user} value={user}>
                    {user}{" "}
                    {userDetail.company_name
                      ? `(${userDetail.company_name})`
                      : ""}
                  </option>
                );
              })}
            </select>
            <button className="add-button" onClick={handleAddSupplier}>
              Add as Supplier
            </button>
          </div>
        </div>

        <table className="suppliers-table">
          <thead>
            <tr>
              <th>Supplier Name</th>
              <th>Company Name</th>
              <th>Email</th>
              <th>Phone Number</th>
            </tr>
          </thead>
          <tbody>
            {supplierDetails.length > 0 ? (
              supplierDetails.map((supplier, index) => (
                <tr key={index}>
                  <td>{supplier.user_id}</td>
                  <td>{supplier.company_name || "N/A"}</td>
                  <td>{supplier.email || "N/A"}</td>
                  <td>{supplier.phone_number || "N/A"}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4">No Suppliers</td>
              </tr>
            )}
          </tbody>
        </table>

        <div className="header-text">
          <h3>Your Utilizers</h3>
          <p className="utilizers-text">List of current collaboration utilizers</p>
        </div>
        <table className="utilizers-table">
          <thead>
            <tr>
              <th>Utilizer Name</th>
              <th>Company Name</th>
              <th>Email</th>
              <th>Phone Number</th>
            </tr>
          </thead>
          <tbody>
            {utilizerDetails.length > 0 ? (
              utilizerDetails.map((utilizer, index) => (
                <tr key={index}>
                  <td>{utilizer.user_id}</td>
                  <td>{utilizer.company_name || "N/A"}</td>
                  <td>{utilizer.email || "N/A"}</td>
                  <td>{utilizer.phone_number || "N/A"}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4">Loading Utilizers...</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default SuppliersSettings;
