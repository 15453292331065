function getApiBaseUrl() {
    const hostname = window.location.hostname;

    if (hostname.includes("localhost")) {
        return "http://localhost:3002"; // Development API
    }
    else {
        return "https://api-arivu.vrtta.green"; // Production API
    }
}

const config = {
    apiUrl: getApiBaseUrl()
}

export default config;