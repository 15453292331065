import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import { usePendingUser } from '../authentication/PendingUserContext';

function PrivateRoute({ ...rest }) {
    const { user } = useAuth();
    const { termsAccepted } = usePendingUser();

    if (!user) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/login" state={{ from: rest.location }} replace />;
    }
    if (!termsAccepted) {
        // Redirect to terms and conditions if the terms are not accepted
        return <Navigate to="/terms_and_conditions" state={{ from: rest.location }} replace />;
    }

    return <Outlet />;
}

export default PrivateRoute;