import React, { useState, useEffect, useMemo } from 'react';
import '../style/components-style/ProcessTable.css';
import config from "../config";
import Grid from "./Grid";
import ProcessEnvFactorButton from "../environment-page-components/ProcessEnvFactorButton";
import { Icon } from '@iconify/react';
import { translations } from '../app_components/Translation';

function ProcessTable({ setSelectedProcessData, setProcessData, selectedRow, setProcessTableGridApi, isSupplier, selectedLanguage, setAllProcessRows }) {

    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [processRowData, setProcessRowData] = useState([]);
    const [isOpen, setIsOpen] = useState(true);
    const [selectedProcessesRows, setSelectedProcessesRows] = useState([]);
    const selectedText = translations[selectedLanguage].processTable;
    const [selectedTab, setSelectedTab] = useState(selectedText.all);

    const isEditable = selectedRow?.collaborator_id == null || selectedRow?.collaborator_id === '';

    const onGridReady = params => {
        setGridApi(params.api);
        setProcessTableGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };

    const refreshProcessData = () => {
        if (!selectedRow || !selectedRow['id']) {
            setProcessRowData([]);
            return;
        }
        const mainTableID = selectedRow['id'];

        fetch(`${config.apiUrl}/api/get-processes-by-id`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                productOrDesignId: mainTableID
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                setProcessRowData(data);
                setAllProcessRows(data);
                setProcessData(data);
            })
            .catch(error => {
                console.error('Error fetching data:', error.message);
            });
    };

    useEffect(() => {
        if (!gridApi) return;

        const onSelectionChanged = () => {
            const selectedNodes = gridApi.getSelectedNodes();
            const selectedData = selectedNodes.map(node => node.data);
            setSelectedProcessData(selectedData);
            setSelectedProcessesRows(selectedData);
        };

        gridApi.addEventListener('selectionChanged', onSelectionChanged);
        return () => {
            gridApi.removeEventListener('selectionChanged', onSelectionChanged);
        };
    }, [gridApi, setSelectedProcessData]);

    useEffect(() => {
        refreshProcessData();
    }, [selectedRow]);

    function formatFloatValue(value, decimalPlaces) {
        return parseFloat(value).toFixed(decimalPlaces);
    }


    // Function to calculate the total GWP
    const calculateTotalGWP = useMemo(() => {
        return processRowData.reduce((total, row) => {
            const gwpValue = parseFloat(row.gwp);
            return !isNaN(gwpValue) ? total + gwpValue : total;
        }, 0).toFixed(2);
    }, [processRowData]);

    useEffect(() => {
        if (!gridApi) return;
    
        fetchEnvFactorData().then((updatedRowData) => {
            const tab = filterTabs.find(tab => tab.label === selectedTab);
            if (!tab) {
                console.error(`No filter found for tab: ${selectedTab}`);
                return;
            }
    
            // Apply the selected tab's filter
            let filteredData = updatedRowData.filter(tab.filter);
    
            // Apply sorting to all tabs
            const sortOrder = {
                'A1': 1, 'A2': 2, 'A3': 3,
                'A4': 4, 'A5': 5,
                'B1': 6, 'B2': 7, 'B3': 8, 'B4': 9, 'B5': 10,
                'C1': 11, 'C2': 12, 'C3': 13, 'C4': 14,
                'D': 15
            };
    
            filteredData.sort((a, b) => {
                const stageA = a.lca_stage;
                const stageB = b.lca_stage;
    
                // If the stage is found in the sortOrder, sort accordingly
                if (sortOrder[stageA] !== undefined && sortOrder[stageB] !== undefined) {
                    return sortOrder[stageA] - sortOrder[stageB];
                }
    
                // If one of the stages is not found in the sortOrder, place it accordingly
                if (sortOrder[stageA] !== undefined) return -1;
                if (sortOrder[stageB] !== undefined) return 1;
    
                // Default comparison for cases not covered
                return stageA.localeCompare(stageB);
            });
    
            gridApi.setRowData(filteredData);
        });
    }, [gridApi, selectedTab, processRowData]);    


    const filterTabs = [
        { label: selectedText.all, filter: () => true },
        { label: selectedText.product, filter: item => ['A1', 'A2', 'A3'].some(prefix => item.lca_stage.startsWith(prefix)) },
        { label: selectedText.construction, filter: item => ['A4', 'A5'].some(prefix => item.lca_stage.startsWith(prefix)) },
        { label: selectedText.use, filter: item => item.lca_stage.startsWith('B') },
        { label: selectedText.endOfLife, filter: item => item.lca_stage.startsWith('C') },
        { label: selectedText.benefits, filter: item => item.lca_stage.startsWith('D') }
    ];

    const lcaStageValues = ['A1: Raw Material Supply', 'A2: Transport to Factory', 'A3: Manufacturing', 'A4: Transport to Site', 'A5: Construction-Installation', 'B1: Use', 'B2: Maintenance', 'B3: Repair', 'B4: Replacement', 'B5: Refurbishment', 'C1: Deconstuction / Demolition', 'C2: Transport to Waste / Disposal', 'C3: Waste Processing', 'C4: Disposal of Waste', 'D: Reuse-Recovery-Recycling Potential'];

    if (!isSupplier) {
        lcaStageValues.push('B1: Use', 'B2: Maintenance', 'B3: Repair', 'B4: Replacement', 'B5: Refurbishment', 'C1: Deconstuction / Demolition', 'C2: Transport to Waste / Disposal', 'C3: Waste Processing', 'C4: Disposal of Waste', 'D: Reuse-Recovery-Recycling Potential');
    }

    const processGridProps = {
        columnDefs: [
            { field: 'id', hide: true },
            { field: 'product_id', hide: true },
            { field: 'design_id', hide: true },
            {
                headerName: selectedText.headers.lcaStage,
                field: 'lca_stage',
                cellEditor: 'agSelectCellEditor',
                editable: isEditable,
                cellEditorParams: {
                    values: lcaStageValues,
                },
                minWidth: 230,
                width: 240,
                checkboxSelection: true,
                headerCheckboxSelection: true,
                cellStyle: { textAlign: 'left' }
            },
            {
                headerName: selectedText.headers.process,
                field: 'name',
                cellEditor: 'agTextCellEditor',
                editable: isEditable,
                minWidth: 150,
                width: 210,
                cellStyle: { textAlign: 'left' }
            },
            {
                headerName: selectedText.headers.gwp,
                field: 'gwp',
                editable: (params) => isEditable && !params.data.hasEnvFactor, // Editable only if the row has no environmental factors
                valueFormatter: (params) => formatFloatValue(params.value, 2),
                minWidth: 180,
                maxWidth: 200,
                cellStyle: { textAlign: 'left' }
            }            
        ],
        rowData: processRowData,
        sideBar: {
            toolPanels: [
                {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                    toolPanelParams: {
                        suppressRowGroups: true,
                        suppressValues: true,
                        suppressPivots: true,
                        suppressPivotMode: true,
                    },
                },
                {
                    id: 'filters',
                    labelDefault: 'Filters',
                    labelKey: 'filters',
                    iconKey: 'filter',
                    toolPanel: 'agFiltersToolPanel',
                },
            ],
            defaultToolPanel: 'filters'
        },
        onGridReady
    };
    

    const toggleContainer = () => setIsOpen(!isOpen);

    const fetchEnvFactorData = () => {
        if (!processRowData || processRowData.length === 0) return Promise.resolve([]);  // Handle no data case
    
        const processIDs = processRowData.map(process => process['id']);
        const fetchPromises = processIDs.map(processID => {
            const url = new URL(`${config.apiUrl}/api/get-env-factor-by-process-id`);
            url.searchParams.append('process_id', processID);
    
            return fetch(url.toString(), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                    return response.json();
                });
        });
    
        return Promise.all(fetchPromises)
            .then(results => {
                const processesWithEnvFactor = new Set(results.flat().map(envFactor => envFactor.process_id));
    
                // Update row data with `hasEnvFactor` property
                const updatedRowData = processRowData.map(item => ({
                    ...item,
                    hasEnvFactor: processesWithEnvFactor.has(item.id),
                }));
    
                return updatedRowData;  // Return the updated data
            })
            .catch(error => {
                console.error('Error fetching data:', error.message);
                return [];  // Return an empty array in case of error
            });
    };    

    useEffect(() => {
        if (processRowData && processRowData.length > 0) {
            fetchEnvFactorData(); // Fetch environmental factor data for processes
        }
    }, [processRowData]);     

    return (
        <div className="process-table">
            <div className="container-header">
                <button className="toggle-button" onClick={toggleContainer}>
                    {isOpen ? (
                        <Icon icon="iconamoon:arrow-up-2" style={{ color: 'black', fontSize: '20px' }} />
                    ) : (
                        <Icon icon="iconamoon:arrow-down-2" style={{ color: 'black', fontSize: '20px' }} />
                    )}
                </button>
                <h2>{selectedText.process}</h2>
            </div>
            {isOpen && (
                <>
                    <div className="filter-bar">
                        {filterTabs.map(tab => (
                            <button key={tab.label} onClick={() => setSelectedTab(tab.label)}
                                className={selectedTab === tab.label ? 'active' : ''}>
                                {tab.label}
                            </button>
                        ))}
                    </div>
                    <div className="grid-container">
                        <div className="grid-wrapper">
                            <Grid {...processGridProps} />
                        </div>
                        <div className="gwp-total">
                            <text className="gwp-total-text">Total: {calculateTotalGWP}</text>
                        </div>
                        <div className="button-container">
                            <ProcessEnvFactorButton
                                selectedRows={selectedRow}
                                selectedProcessesRows={selectedProcessesRows}
                                tableGridApi={gridApi}
                                isForProcess={true} // Since this is for processes
                                product={isSupplier ? selectedRow : null} // If it's a supplier, pass product
                                design={!isSupplier ? selectedRow : null} // If not, pass design
                                process={null} // Assuming process is null here
                                refreshData={refreshProcessData} // Function to refresh data
                                selectedLanguage={selectedLanguage}
                                isEditable={selectedRow && !selectedRow.collaborator_id} // Editable if selectedRow exists and has no collaborator_id
                                isAddButtonDisabled={!selectedRow || !!selectedRow.collaborator_id} // Disable add button if no row is selected or if it's a collaborated product
                            />
                        </div>

                    </div>
                </>
            )}
        </div>
    );
}

export default ProcessTable;
