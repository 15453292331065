import React, { useMemo, useState, useEffect, useCallback } from 'react';
import Select from 'react-select';
import Header from "../app_components/Header";
import PhaseCard from "../comparison-components/PhaseCard";
import "../style/pages-style/Comparison.css";
import { Bar } from "react-chartjs-2";
import { useTable } from "react-table";
import LifeCycleAssessment from "../environment-page-components/LifeCycleAssessment";
import config from '../config';
import { useAuth } from "../authentication/AuthProvider";
import { translations } from "../app_components/Translation";

function OrganizationalComparison({ selectedLanguage }) {
    const [comparisonType, setComparisonType] = useState("");
    const [comparisonBasedOn, setComparisonBasedOn] = useState("");
    const [selectedValues, setSelectedValues] = useState([]);
    const [comparisonBasedOnOptions, setComparisonBasedOnOptions] = useState([]);
    const [footprintSummaryData, setFootprintSummaryData] = useState([]);
    const [selectedComparisonData, setSelectedComparisonData] = useState({});
    const [percentageDifferences, setPercentageDifferences] = useState({});
    const { user } = useAuth();
    const userId = user ? user.username : null;

    const selectedText = translations[selectedLanguage]?.comparison || {};

    const comparisonTypeOptions = [
        { value: 'Supplier', label: selectedText.supplier || 'Supplier' },
        { value: 'Utilizer', label: selectedText.utilizer || 'Utilizer' }
    ];

    const phaseKeys = {
        'Product Phase': 'productPhase',
        'Construction Phase': 'constructionPhase',
        'Use Phase': 'usePhase',
        'End-of-life Phase': 'endOfLifePhase',
        'Benefits Phase': 'benefitsPhase'
    };

    const fetchData = useCallback(() => {
        if (!userId || !comparisonType) return;

        console.log("Fetching data with", { comparisonType, userId });

        fetch(`${config.apiUrl}/get_product_or_design_name?comparison_type=${comparisonType}&user_id=${userId}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        })
        .then(response => response.json())
        .then(data => {
            console.log("Fetched data:", data);

            if (data.error) {
                throw new Error(data.error);
            }

            if (data.results && data.results.length > 0) {
                const options = data.results.map(item => ({
                    value: item.id,
                    label: item.name
                }));
                setComparisonBasedOnOptions(options);
                console.log("Options set:", options);
            } else {
                setComparisonBasedOnOptions([]);
            }
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
    }, [comparisonType, userId]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleApply = () => {
        console.log('Applying selections:', {
            comparisonType,
            comparisonBasedOn,
            selectedValues
        });

        const allSelectedValues = [...selectedValues];
        if (!allSelectedValues.includes(comparisonBasedOn)) {
            allSelectedValues.push(comparisonBasedOn);
        }

        const queryParams = new URLSearchParams({
            comparison_type: comparisonType,
            selected_values: allSelectedValues.join(',')
        });
        console.log('Query Params:', queryParams.toString());

        fetch(`${config.apiUrl}/get_gwp_per_lca_phase?${queryParams.toString()}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        })
        .then(response => response.json())
        .then(data => {
            console.log('GWP per LCA phase data:', data);

            const formattedData = Object.entries(data).map(([rowId, phases]) => {
                console.log('Row ID:', rowId);
                console.log('Phases:', phases);
                const productOption = comparisonBasedOnOptions.find(option => option.value === rowId);
                const productName = productOption ? productOption.label : rowId;

                return {
                    product: productName,
                    productPhase: parseFloat(phases['Product Phase'] || 0).toFixed(2),
                    constructionPhase: parseFloat(phases['Construction Phase'] || 0).toFixed(2),
                    usePhase: parseFloat(phases['Use Phase'] || 0).toFixed(2),
                    endOfLifePhase: parseFloat(phases['End-of-life Phase'] || 0).toFixed(2),
                    benefitsPhase: parseFloat(phases['Benefits Phase'] || 0).toFixed(2),
                    total: parseFloat(
                        (phases['Product Phase'] || 0) +
                        (phases['Construction Phase'] || 0) +
                        (phases['Use Phase'] || 0) +
                        (phases['End-of-life Phase'] || 0) +
                        (phases['Benefits Phase'] || 0)
                    ).toFixed(2)
                };
            });

            console.log('Formatted Data:', formattedData);
            setFootprintSummaryData(formattedData);

            const selectedData = formattedData.find(item => item.product === comparisonBasedOnOptions.find(option => option.value === comparisonBasedOn)?.label);
            console.log('Selected Comparison Data:', selectedData);
            setSelectedComparisonData(selectedData || {});

            const percentageDifferences = {};
            formattedData.forEach(item => {
                if (item.product !== selectedData.product) {
                    percentageDifferences[item.product] = percentageDifference(parseFloat(item.total), parseFloat(selectedData.total));
                }
            });

            console.log('Percentage Differences:', percentageDifferences);
            setPercentageDifferences(percentageDifferences);
        })
        .catch(error => {
            console.error('Error fetching GWP data:', error);
        });
    };

    const footprintSummaryColumns = useMemo(() => [
        {
            Header: selectedText.product || 'Product',
            accessor: 'product'
        },
        {
            Header: selectedText.productPhase || 'Product Phase',
            accessor: 'productPhase',
            Cell: ({ value }) => `${value} kg CO2eq`,
            style: { backgroundColor: '#79AC78' }
        },
        {
            Header: selectedText.constructionPhase || 'Construction Phase',
            accessor: 'constructionPhase',
            Cell: ({ value }) => `${value} kg CO2eq`,
            style: { backgroundColor: '#B0D9B1' }
        },
        {
            Header: selectedText.usePhase || 'Use Phase',
            accessor: 'usePhase',
            Cell: ({ value }) => `${value} kg CO2eq`,
            style: { backgroundColor: '#d2eed8' }
        },
        {
            Header: selectedText.endOfLifePhase || 'End-of-life Phase',
            accessor: 'endOfLifePhase',
            Cell: ({ value }) => `${value} kg CO2eq`,
            style: { backgroundColor: '#effbf1' }
        },
        {
            Header: selectedText.benefitsPhase || 'Benefits Phase',
            accessor: 'benefitsPhase',
            Cell: ({ value }) => `${value} kg CO2eq`,
            style: { backgroundColor: '#f5f5f5' }
        },
        {
            Header: selectedText.total || 'Total',
            accessor: 'total',
            Cell: ({ value }) => `${value} kg CO2eq`
        }
    ], [selectedText]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns: footprintSummaryColumns, data: footprintSummaryData });

    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString(selectedLanguage === 'FR' ? 'fr-FR' : 'en-US', { year: 'numeric', month: 'long', day: 'numeric' });

    const colors = [
        'rgba(255, 99, 132, 0.5)',
        'rgba(53, 162, 235, 0.5)',
        'rgba(75, 192, 192, 0.5)',
        'rgba(255, 206, 86, 0.5)',
        'rgba(153, 102, 255, 0.5)',
        'rgba(255, 159, 64, 0.5)'
    ];

    const barData = {
        labels: [
            selectedText.productPhase || 'Product Phase',
            selectedText.constructionPhase || 'Construction Phase',
            selectedText.usePhase || 'Use Phase',
            selectedText.endOfLifePhase || 'End-of-life Phase',
            selectedText.benefitsPhase || 'Benefits Phase'
        ],
        datasets: footprintSummaryData.map((product, index) => ({
            label: product.product,
            data: [
                parseFloat(product.productPhase),
                parseFloat(product.constructionPhase),
                parseFloat(product.usePhase),
                parseFloat(product.endOfLifePhase),
                parseFloat(product.benefitsPhase)
            ],
            backgroundColor: colors[index % colors.length],
            borderColor: colors[index % colors.length].replace('0.5', '1'),
            borderWidth: 1,
        })),
    };

    console.log('Bar Data:', barData);

    const barOptions = {
        indexAxis: 'x',
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        plugins: {
            legend: {
                position: 'right',
            },
            title: {
                display: true,
            },
        },
        maintainAspectRatio: true,
        scales: {
            y: {
                title: {
                    display: true,
                    text: selectedText.gwp || 'GWP [kg CO2eq]'
                }
            },
            x: {
                title: {
                    display: true,
                    text: selectedText.phases || 'Phases'
                }
            }
        }
    };

    const calculateAverages = (data) => {
        const totals = {
            productPhase: 0,
            constructionPhase: 0,
            usePhase: 0,
            endOfLifePhase: 0,
            benefitsPhase: 0,
            count: data.length
        };

        data.forEach(item => {
            totals.productPhase += parseFloat(item.productPhase);
            totals.constructionPhase += parseFloat(item.constructionPhase);
            totals.usePhase += parseFloat(item.usePhase);
            totals.endOfLifePhase += parseFloat(item.endOfLifePhase);
            totals.benefitsPhase += parseFloat(item.benefitsPhase);
        });

        return {
            productPhase: (totals.productPhase / totals.count).toFixed(2) || 0,
            constructionPhase: (totals.constructionPhase / totals.count).toFixed(2) || 0,
            usePhase: (totals.usePhase / totals.count).toFixed(2) || 0,
            endOfLifePhase: (totals.endOfLifePhase / totals.count).toFixed(2) || 0,
            benefitsPhase: (totals.benefitsPhase / totals.count).toFixed(2) || 0
        };
    };

    const averages = calculateAverages(footprintSummaryData);
    console.log('Averages:', averages);

    const percentageDifference = (value1, value2) => {
        if (value1 === 0 || value2 === 0) {
            return 0;
        }
        const denominator = (value1 + value2) / 2;
        return denominator !== 0 ? (((value1 - value2) / denominator) * 100).toFixed(2) : 0;
    };

    const percentageDifferenceBarData = {
        labels: Object.keys(percentageDifferences),
        datasets: [{
            label: selectedText.percentageDifference || 'Percentage Difference',
            data: Object.values(percentageDifferences).map(value => parseFloat(value)),
            backgroundColor: Object.values(percentageDifferences).map(value => value < 0 ? 'rgba(75, 192, 192, 0.5)' : 'rgba(255, 99, 132, 0.5)'),
            borderColor: Object.values(percentageDifferences).map(value => value < 0 ? 'rgba(75, 192, 192, 1)' : 'rgba(255, 99, 132, 1)'),
            borderWidth: 1
        }]
    };

    const percentageDifferenceBarOptions = {
        indexAxis: 'y',
        scales: {
            x: {
                title: {
                    display: true,
                    text: selectedText.percentageDifferenceChart || 'Percentage Difference (%)'
                }
            },
            y: {
                title: {
                    display: true,
                    text: selectedText.products || 'Products'
                }
            }
        },
        plugins: {
            legend: {
                display: true,
                labels: {
                    generateLabels: function(chart) {
                        const datasets = chart.data.datasets;
                        return datasets[0].data.map((data, i) => ({
                            text: `${data < 0 ? selectedText.lessThan || '% less than' : selectedText.moreThan || '% more than'} ${comparisonBasedOnOptions.find(option => option.value === comparisonBasedOn)?.label}`,
                            fillStyle: data < 0 ? 'rgba(75, 192, 192, 0.5)' : 'rgba(255, 99, 132, 0.5)',
                            strokeStyle: data < 0 ? 'rgba(75, 192, 192, 1)' : 'rgba(255, 99, 132, 1)',
                            hidden: false,
                            index: i
                        }));
                    }
                }
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        return `${context.raw}%`;
                    }
                }
            }
        }
    };

    return (
        <div className="comparison-page">
            <Header selectedLanguage={selectedLanguage}/>
            <div className="comparison-container">
                <div className="inventory-date">
                    <h3>{selectedText.inventoryAsOf || 'Inventory as of'} {formattedDate}</h3>
                </div>
                <div className="dropdown-container">
                    <Select
                        options={comparisonTypeOptions}
                        value={comparisonTypeOptions.find(option => option.value === comparisonType)}
                        onChange={selectedOption => setComparisonType(selectedOption.value)}
                        className="dropdown"
                        placeholder={selectedText.selectComparisonType || 'Select Comparison Type'}
                    />
                    <Select
                        options={comparisonBasedOnOptions}
                        value={comparisonBasedOnOptions.find(option => option.value === comparisonBasedOn)}
                        onChange={selectedOption => setComparisonBasedOn(selectedOption.value)}
                        className="dropdown"
                        placeholder={selectedText.comparisonBasedOn || 'Comparison Based On'}
                        isSearchable
                    />
                    <Select
                        isMulti
                        options={comparisonBasedOnOptions}
                        value={comparisonBasedOnOptions.filter(option => selectedValues.includes(option.value))}
                        onChange={selectedOptions => setSelectedValues(selectedOptions.map(item => item.value))}
                        className="dropdown"
                        placeholder={selectedText.selectValuesToCompare || 'Select Values to Compare'}
                    />
                    <button onClick={handleApply} className="apply-button">
                        {selectedText.apply || 'Apply'}
                    </button>
                </div>
                <div className="phase-cards-container">
                    {Object.keys(phaseKeys).map(phase => {
                        const phaseKey = phaseKeys[phase];
                        return (
                            <PhaseCard
                                key={phase}
                                phase={phase}
                                current={parseFloat(selectedComparisonData[phaseKey]).toFixed(2) || 0}
                                average={parseFloat(averages[phaseKey]).toFixed(2) || 0}
                                isLess={(parseFloat(selectedComparisonData[phaseKey]).toFixed(2) || 0) < (parseFloat(averages[phaseKey]).toFixed(2) || 0)}
                                selectedLanguage={selectedLanguage}
                            />
                        );
                    })}
                </div>
                <div className="comparison-bar-chart">
                    <h2>{selectedText.environmentalFootprint || 'Environmental Footprint'}</h2>
                    <Bar data={barData} options={barOptions} />
                </div>
                <div className="footprint-section">
                    <div className="footprint_table-container">
                        <h2>{selectedText.footprintSummary || 'Footprint Summary'}</h2>
                        <table {...getTableProps()} className="footprint-table">
                            <thead>
                                {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(column => (
                                            <th key={column.id} {...column.getHeaderProps()} style={column.style}>{column.render('Header')}</th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {rows.map(row => {
                                    prepareRow(row);
                                    return (
                                        <tr key={row.id} {...row.getRowProps()}>
                                            {row.cells.map(cell => (
                                                <td key={cell.id} {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                            ))}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className="footprint-graph-container">
                        <h2>{selectedText.percentageDifference || 'Percentage Difference'}</h2>
                        <Bar data={percentageDifferenceBarData} options={percentageDifferenceBarOptions} />
                    </div>
                </div>
                {/* <div className="life-cycle-assessment-div">
                    <LifeCycleAssessment selectedLanguage={selectedLanguage} />
                </div> */}
            </div>
        </div>
    );
}

export default OrganizationalComparison;
