import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../style/pages-style/TermsAndConditions.css';
import { useAuth } from '../authentication/AuthProvider';
import { usePendingUser } from '../authentication/PendingUserContext';
import { translations } from '../app_components/Translation';

const TermsAndConditions = ({ selectedLanguage }) => {
    const navigate = useNavigate();
    const auth = useAuth();
    const { pendingUser, setPendingUser, setTermsAccepted } = usePendingUser();
    const selectedText = translations[selectedLanguage]?.termsAndConditions || {};

    const handleAccept = () => {
        if (pendingUser) {
            auth.login({ username: pendingUser });
            setPendingUser(null);
            setTermsAccepted(true);
            localStorage.setItem('termsAccepted', 'true');
        }
        navigate('/homeviewtype');
    };

    const handleDecline = () => {
        setPendingUser(null);
        auth.logout();
        setTermsAccepted(false);
        localStorage.setItem('termsAccepted', 'false');
        navigate('/');
    };

    return (
        <div className="terms-container">
            <h1 className="terms-header">{selectedText.termsHeader || 'Terms and Conditions'}</h1>
            <div className="terms-content">
                {selectedText.termsContent ? selectedText.termsContent.map((paragraph, index) => (
                    <p key={index}>{paragraph}</p>
                )) : (
                    <>
                        <p>These are the terms and conditions...</p>
                        <p>More details about the terms and conditions...</p>
                    </>
                )}
            </div>
            <div className="terms-buttons">
                <button onClick={handleDecline} className="button-logout">
                    {selectedText.decline || 'Decline'}
                </button>
                <button onClick={handleAccept} className="button-login">
                    {selectedText.accept || 'Accept'}
                </button>
            </div>
        </div>
    );
};

export default TermsAndConditions;
