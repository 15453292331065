import React, { useState, useEffect, useMemo } from 'react';
import { useTable } from 'react-table';
import { Icon } from '@iconify/react';
import '../style/components-style/SystemBoundary.css';
import { translations } from '../app_components/Translation';

function SystemBoundary({ processData, selectedLanguage, setSystemBoundaryTable }) {
    const [isOpen, setIsOpen] = useState(true);

    const toggleContainer = () => setIsOpen(!isOpen);

    const selectedText = translations[selectedLanguage]?.systemBoundary || {};

    const defaultData = useMemo(() => ({
        A1: '❌', A2: '❌', A3: '❌', A4: '❌', A5: '❌',
        B1: '❌', B2: '❌', B3: '❌', B4: '❌', B5: '❌',
        C1: '❌', C2: '❌', C3: '❌', C4: '❌', D: '❌'
    }), []);

    const data = useMemo(() => {
        const rowData = [{ ...defaultData }];
        processData.forEach(item => {
            const prefix = item.lca_stage.split(': ')[0];
            if (rowData[0].hasOwnProperty(prefix)) {
                rowData[0][prefix] = `${item.gwp.toFixed(2)}`;
            }
        });
        return rowData;
    }, [defaultData, processData]);

    const columns = useMemo(() => [
        {
            Header: selectedText.productStage || 'Product stage',
            accessor: 'product_stage',
            columns: [
                { Header: 'A1', accessor: 'A1', subHeader: selectedText.rawMaterialSupply || 'Raw Material Supply', backgroundColor: '#79AC78' },
                { Header: 'A2', accessor: 'A2', subHeader: selectedText.transportToFactory || 'Transport to Factory', backgroundColor: '#79AC78' },
                { Header: 'A3', accessor: 'A3', subHeader: selectedText.manufacturing || 'Manufacturing', backgroundColor: '#79AC78' },
            ],
        },
        {
            Header: selectedText.constructionStage || 'Construction stage',
            accessor: 'construction_stage',
            columns: [
                { Header: 'A4', accessor: 'A4', subHeader: selectedText.transportToSite || 'Transport to Site', backgroundColor: '#B0D9B1' },
                { Header: 'A5', accessor: 'A5', subHeader: selectedText.constructionInstallation || 'Construction-Installation', backgroundColor: '#B0D9B1' }
            ],
        },
        {
            Header: selectedText.useStage || 'Use stage',
            accessor: 'use_stage',
            columns: [
                { Header: 'B1', accessor: 'B1', subHeader: selectedText.use || 'Use', backgroundColor: '#d2eed8' },
                { Header: 'B2', accessor: 'B2', subHeader: selectedText.maintenance || 'Maintenance', backgroundColor: '#d2eed8' },
                { Header: 'B3', accessor: 'B3', subHeader: selectedText.repair || 'Repair', backgroundColor: '#d2eed8' },
                { Header: 'B4', accessor: 'B4', subHeader: selectedText.replacement || 'Replacement', backgroundColor: '#d2eed8' },
                { Header: 'B5', accessor: 'B5', subHeader: selectedText.refurbishment || 'Refurbishment', backgroundColor: '#d2eed8' }
            ],
        },
        {
            Header: selectedText.endOfLifeStage || 'End-of-life stage',
            accessor: 'end_of_life_stage',
            columns: [
                { Header: 'C1', accessor: 'C1', subHeader: selectedText.deconstructionDemolition || 'Deconstruction / Demolition', backgroundColor: '#effbf1' },
                { Header: 'C2', accessor: 'C2', subHeader: selectedText.transportToWasteDisposal || 'Transport to Waste / Disposal', backgroundColor: '#effbf1' },
                { Header: 'C3', accessor: 'C3', subHeader: selectedText.wasteProcessing || 'Waste Processing', backgroundColor: '#effbf1' },
                { Header: 'C4', accessor: 'C4', subHeader: selectedText.disposalOfWaste || 'Disposal of Waste', backgroundColor: '#effbf1' }
            ]
        },
        {
            Header: selectedText.beyondBuildingLifeCycle || 'Beyond the building life cycle',
            accessor: 'beyond_building_life_cycle',
            columns: [
                { Header: 'D', accessor: 'D', subHeader: selectedText.reuseRecoveryRecyclingPotential || 'Reuse-Recovery-Recycling Potential', backgroundColor: '#E0F2FA', width: 50 }
            ]
        }
    ], [selectedText]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data
    });

    useEffect(() => {
        const tableData = {
            columns,
            data,
            headerGroups: headerGroups.map(group => ({
                headers: group.headers.map(header => ({
                    Header: header.Header,
                    accessor: header.accessor,
                    subHeaders: header.columns?.map(subColumn => ({
                        Header: subColumn.Header,
                        accessor: subColumn.accessor,
                        subHeader: subColumn.subHeader,
                        backgroundColor: subColumn.backgroundColor
                    }))
                }))
            }))
        };

        setSystemBoundaryTable(tableData);
        console.log('tableData', tableData);
    }, [columns, data, headerGroups, setSystemBoundaryTable]);

    return (
        <div className='boundary-table-container'>
            <div className="container-header">
                <button className="toggle-button" onClick={toggleContainer}>
                    {isOpen ? (
                        <Icon icon="iconamoon:arrow-up-2" style={{ color: 'black', fontSize: '20px' }} />
                    ) : (
                        <Icon icon="iconamoon:arrow-down-2" style={{ color: 'black', fontSize: '20px' }} />
                    )}
                </button>
                <h2>{selectedText.title || 'System Boundary (Cradle To Grave)'}</h2>
            </div>
            {isOpen && (
                <table {...getTableProps()} className='boundary-table'>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps()} colSpan={column.columns ? column.columns.length : 1}
                                        style={{
                                            background: '#D9E9F9',
                                            padding: '10px',
                                            textAlign: 'center',
                                            border: '1px solid #ccc',
                                        }}>
                                        <div className="header-content">{column.render('Header')}</div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    column.columns ? column.columns.map(subColumn => (
                                        <th {...subColumn.getHeaderProps()}
                                            className={subColumn.customClass || ''}
                                            style={{
                                                background: subColumn.backgroundColor,
                                                textAlign: 'center',
                                                border: '1px solid #ccc',
                                                writingMode: 'vertical-rl',
                                                transform: 'rotate(180deg)',
                                            }}>
                                            <div className="header-content">
                                                <span className="subheader">{subColumn.subHeader}</span>
                                            </div>
                                        </th>
                                    )) : null
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map(row => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        const subColumn = columns.flatMap(col => col.columns).find(c => c.accessor === cell.column.id);
                                        return (
                                            <td {...cell.getCellProps()} style={{
                                                padding: '20px',
                                                textAlign: 'center',
                                                border: '1px solid #ccc',
                                                background: subColumn ? subColumn.backgroundColor : undefined
                                            }}>
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            )}
        </div>
    );
}

export default SystemBoundary;
